import * as Yup from "yup";
import * as errorMessages from "./form-errors";
import * as validations from "./common-validations";
import { checkIsBeforeTime } from "../utils/helpers";
import { LEAD, PROSPECT } from "../utils/constants"


export const activityForm = {
  type: PROSPECT,
  appointment: true,
  send_email: true,
  title: "",
  details: "",
  appointment_datetime: new Date(),
  follow_up_datetime: new Date(),
  start_datetime: new Date(),
  end_datetime: new Date(),
  csm_id: null,
  community_id: null,
  appointment_type_id: null,
  division_id: null
};

export const addActivitySchema = Yup.object().shape({
  title: validations.isSpace
    .required(errorMessages.REQUIRE_FIELD)
    .max(64, errorMessages.TITLE_LONG)
    .default("")
    .nullable(),
  details: validations.isSpace
    .max(256, "Details must not be more than 256 characters")
    .default("")
    .nullable(),
  csm_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().default("Select...").nullable(),
    otherwise: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
  }),
  appointment_type_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),
  community_id: Yup.string().when("type", {
    is: (type) => type === LEAD,
    then: Yup.string()
      .required(errorMessages.REQUIRE_FIELD)
      .default("Select...")
      .nullable(),
    otherwise: Yup.string().default("Select...").nullable(),
  }),
  appointment_datetime: Yup.date().required().default(new Date()),
  follow_up_datetime: Yup.date().required().default(new Date()),
  timecheck: Yup.string().when(["start_datetime", "end_datetime", "appointment"], {
    is: (start_datetime, end_datetime, appointment) => {
      if (appointment) {
        return !checkIsBeforeTime(start_datetime, end_datetime);
      } else return false;
    },
    then: Yup.string().required(errorMessages.END_TIME),
    otherwise: Yup.string(),
  }),
  start_datetime: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().required(errorMessages.REQUIRE_FIELD),
    otherwise: Yup.string(),
  }),
});

export const addLeadActivitySchema = Yup.object().shape({
  title: validations.isSpace
    .required(errorMessages.REQUIRE_FIELD)
    .max(64, errorMessages.TITLE_LONG)
    .default("")
    .nullable(),
  details: validations.isSpace
    .max(256, "Details must not be more than 256 characters")
    .default("")
    .nullable(),
  csm_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().default("Select...").nullable(),
    otherwise: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
  }),
  appointment_type_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),
  community_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().default("Select...").nullable(),
    otherwise: Yup.string()
      .required(errorMessages.REQUIRE_FIELD)
      .default("Select...")
      .nullable(),
  }),
  division_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),
  appointment_datetime: Yup.date().required().default(new Date()),
  follow_up_datetime: Yup.date().required().default(new Date()),
  startTime: Yup.string().required(errorMessages.REQUIRE_FIELD).default("12:00"),
});
export const addRealtorActivitySchema = Yup.object().shape({
  title: validations.isSpace
    .required(errorMessages.REQUIRE_FIELD)
    .max(64, errorMessages.TITLE_LONG)
    .default("")
    .nullable(),
  details: validations.isSpace
    .max(256, "Details must not be more than 256 characters")
    .default("")
    .nullable(),
  csm_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().default("Select...").nullable(),
    otherwise: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
  }),
  appointment_type_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),
  community_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string().default("Select...").nullable(),
    otherwise: Yup.string()
      .required(errorMessages.REQUIRE_FIELD)
      .default("Select...")
      .nullable(),
  }),
  division_id: Yup.string().when(["appointment"], {
    is: (appointment) => appointment,
    then: Yup.string()
      .default("Select...")
      .required(errorMessages.REQUIRE_FIELD)
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),
  appointment_datetime: Yup.date().required().default(new Date()),
  follow_up_datetime: Yup.date().required().default(new Date()),
  startTime: Yup.string().required(errorMessages.REQUIRE_FIELD).default("12:00"),
})