import React from "react";
import moment from "moment-timezone";
import { extendMoment } from "moment-range";
import { PhoneCell } from "../components/new-table";
import {
  FaBan,
  FaTimes,
  FaEnvelope,
  FaCalendarCheck,
  FaCalendarDay,
  FaListAlt,
  FaPhone,
  FaHome,
} from "react-icons/fa";
import { Button } from "react-bootstrap";
import CallIn from "../images/call-in.svg";
import CallOut from "../images/call-out.svg";
import TextIn from "../images/text-in.svg";
import TextOut from "../images/text-out.svg";
import EmailIn from "../images/email-in.svg";
import EmailOut from "../images/email-out.svg";
import UnsubscribedEmailIcon from "../images/unsubscribed-email.svg";
import ListPopup from "../components/list-popup/list-popup";
import _ from "lodash";

const moment_range = extendMoment(moment);

/**@module Helpers */

const DATE_FORMAT = "MM/DD/YYYY";
const DATE_TIME_FORMAT = "MM-DD-YYYY - hh:mmA";
const DATE_TIME_FORMAT_24_HOURS = "MM-DD-YYYY - HH:mm";

/** to check object doesn't contain falsy value
 * @param {*} object
 * @returns {Boolean}
 */
export function isNotNULL(object) {
  return !!object;
}

/**
 * to check if value contains content
 * @param {*} filterValue
 * @returns {Boolean}
 */
export function hasValue(filterValue) {
  if (
    filterValue === undefined ||
    filterValue === null ||
    filterValue === NaN ||
    filterValue === ""
  ) {
    return false;
  }
  return true;
}

/**
 * to get dateString value
 * @param {String} value
 */
export function getDate(value) {
  return new Date(value).toDateString();
}

/**
 * returns activity date-range string from sunday to monday
 * @returns {String}
 */
export function getWeeklyActivityDate() {
  const monday = isTodaySunday() ? moment().day(-6) : moment().day("monday");
  const sunday = isTodaySunday() ? moment().day(0) : moment().day(7);

  return `${monday.format("MMM D")} — ${sunday.format(
    "MMM D"
  )}, ${sunday.format("YYYY")}`;
}

/**
 * returns month of given date
 * @returns
 */
export function getMonth(date) {
  return moment(date).format("MMMM");
}

/**
 * returns activity date-range string from start of current month to current date
 * @returns {String}
 */
export function getMonthlyActivityDate() {
  const startOfMonth = moment().startOf("month");
  const currentDate = moment();

  return `${startOfMonth.format("MMM D")} — ${currentDate.format(
    "MMM D"
  )}, ${startOfMonth.format("YYYY")}`;
}

/**
 * Cuts the string lenth shorter upto maxCharacters
 * @param {String} input string to be truncated
 * @param {Number} maxCharacters number of characters allowed in the string
 */
export function truncate(input, maxCharacters) {
  if (input.length > maxCharacters) {
    return `${input.substring(0, maxCharacters)} ...`;
  } else {
    return input;
  }
}

/**
 * If endDate is provided, it returns formats the date from 7 days earier, otherwise formats the same date and
 * returns it
 * @param {*} isEndDate
 * @returns {Date}
 */
export function getDate_yyyymmdd_format(isEndDate) {
  let date = moment();
  if (isEndDate) {
    date = date.subtract(7, "d").format("YYYY/MM/DD");
  } else {
    date = date.format("YYYY/MM/DD");
  }
  return date;
}

/**
 * Format date in DD MMMM, YYYY i.e. 29 September, 2020
 * @param {Date} date
 * @returns {Date}
 */
export function getDate_ddmmmmyyyy_format(date) {
  return moment(date).format("DD MMMM, YYYY");
}

export function formatDate(date) {
  return moment(date).format(DATE_FORMAT);
}

/**
 * This function is used to calculate expiry date. It takes a date and creates a new date that is 183 days from
 * "date". It formats this new date and returns.
 * @param {String} date the date from which expiry should be calculated
 * @returns {Date}
 */
export function expiryFormatDate(date) {
  let today = new Date(date);
  let numberOfDaysToAdd = 183;
  today.setDate(today.getDate() + numberOfDaysToAdd);
  return moment(today).format(DATE_FORMAT);
}

export function formatReportDateTime(date) {
  return moment(date).format(DATE_TIME_FORMAT);
}

export function formatActivityDate(date) {
  return formatDate(date) + " " + moment(date).format("LT");
}

export function formatNotificationDate(date) {
  return `${formatDate(date)} at ${moment(date).format("LT")}`;
}

export function formatTime(date) {
  return moment(date).format("hh:mmA");
}

export function formatTimeHhMm(date, format) {
  return moment(date).format(format);
}

/**
 * A function that returns date in local time format in given timezone from a DateTime Object
 * @param {DateTime} date Date from which time has to be extracted
 * @param {String} timezone timezone to which the date has to be converted
 */
export function formatLocalTime(date, timezone) {
  return moment.tz(date, timezone).format("LT");
}

/**
 * A function that returns current timezone
 */
export function getCurrentTimezone() {
  return moment.tz.guess();
}

/**
 * A function that returns the date with the time set to the start of the day
 * @param {DateTime} date
 */
export function getStartOfDay(date) {
  const start_date = new Date(date);
  start_date.setHours(0, 0, 1, 0);
  return start_date;
}

/**
 * A function that returns the date with the time set to the end of the day
 * @param {DateTime} date
 */
export function getEndOfDay(date) {
  const end_date = new Date(date);
  end_date.setHours(23, 59, 59, 999);
  return end_date;
}

/**
 * A function that returns the difference between two DateTime values in minutes
 * @param {DateTime} startDate The start date
 * @param {DateTime} endDate The end date
 * @returns Minutes difference between two DateTime values
 */
export function getHourDiff(startDate, endDate) {
  const startTime = moment(new Date(startDate));
  const endTime = moment(new Date(endDate));

  const duration = moment.duration(endTime.diff(startTime));
  return duration.asMinutes();
}

/**
 * A function that returns if the date provided has time in AM or PM
 * @param {DateTime} date The date from which to check whether time is in AM or PM
 * @returns
 */
export function getDateTimeAMPM(date) {
  return moment(date).format("A");
}

/**
 * A function that checks whether two time ranges are conflicting with one another. It uses moment-range library to compare both ranges of time.
 * @param {Object} data Data from which to access the start and end date for the first range
 * @param {String} accessor an accessor string to access the data
 * @param {DateTime} date Date on which to compare the times
 * @param {String} time1 Starting time for the second range
 * @param {String} time2 Ending time for the second range
 */
export const isTimeConflicting = (data, accessor, date, time1, time2) => {
  let conflict = false;

  //Split the start and end times
  const startTime = time1.split(":");
  const endTime = time2.split(":");

  //Set the start and end times to the DateTime Object as moment-range only takes DateTimes objects
  const start_dateTime = new Date(date);
  start_dateTime.setHours(startTime[0]);
  start_dateTime.setMinutes(startTime[1]);

  const end_dateTime = new Date(date);
  end_dateTime.setHours(endTime[0]);
  end_dateTime.setMinutes(endTime[1]);

  //Create the first range
  const range1 = moment_range.range(
    start_dateTime.toUTCString(),
    end_dateTime.toUTCString()
  );
  data.length &&
    data.map((field) => {
      field[accessor].length &&
        field[accessor].map((appointment) => {
          const start = new Date(appointment.start_dateTime);
          const end = new Date(appointment.end_dateTime);

          //Set seconds of to zero as that conflicts when checking for adjacent times
          start.setSeconds("00");
          end.setSeconds("00");
          const range2 = moment_range.range(start, end);

          //Check if the ranges are overlapping, adjacent is set to false, so that edge cases do not return an error
          if (range1.overlaps(range2, { adjacent: false })) {
            conflict = true;
          }
        });
    });

  return conflict;
};

/**
 * This function is similar to {@link module:Helpers.expiryFormatDate} except that it always calculates expiry from
 * current date and returns new date without formatting.
 */
export function getExpiredDate() {
  let currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 6)
  return currentDate;
}

export function formatData(data) {
  return data && data.name;
}

/**
 * This function returns the start date of the calendar month.
 * @returns {String} Returns startDate
 */
export function getMonthStartDate() {
  const currentDate = new Date();
  const startDate = moment(currentDate).startOf("month").subtract(6, "d");

  return startDate.toISOString();
}

/**
 * This function returns the end date of the calendar month.
 * @returns {String} Returns endDate
 */
export function getMonthEndDate() {
  const currentDate = new Date();
  const endDate = moment(currentDate).endOf("month").add(6, "d");

  return endDate.toISOString();
}

export const setAppointmentEndTimeFormat = (startTime, apptDate, duration) => {
  const date = new Date(apptDate);
  date.setHours(startTime.split(":")[0], startTime.split(":")[1], 0, 0);
  const endDateTime = addTimeToDate(date, duration, "minutes");
  return endDateTime.utc();
};

/**
 * This fucntion formats form submission data to be shown on details page in calendar and past-activities. This
 * function is used in {@link module:Helpers.formatDataForCalendar}
 * @param {Object} data data to be formatted
 * @param {String} type tells about the type of page for which data is being formatted
 */
function prepareSubmissionsDataForCalendar(data, type) {
  let submissions = [];

  if (type === "webform") {
    if (isNotNULL(data)) {
      submissions.push({
        ...data,
        typeTitle: "Web Form",
        start: new Date(data.created_at),
        end: new Date(data.created_at),
        __typename: "CustomerFormSubmission",
      });
    }
  } else {
    if (isNotNULL(data.form_submissions) && data.form_submissions.length > 0) {
      submissions = data.form_submissions.map((submissions) => {
        return {
          ...submissions,
          typeTitle: "Web Form",
          start: new Date(submissions.created_at),
          end: new Date(submissions.created_at),
        };
      });
    }
  }

  return submissions;
}

/**
 * This fucntion formats appointments data to be shown on details page in calendar and past-activities. This function
 * is used in {@link module:Helpers.formatDataForCalendar}. "Appointment" is set as the title for this event and
 * appointment start end date are set to show this event inside calendar
 * @param {Object} data data to be formatted
 */
function prepareAppointmentsDataForCalendar(data, type) {
  let appointments = [];
  if (isNotNULL(data.appointments) && data.appointments.length > 0) {
    appointments = data.appointments.map((appointment) => {
      return {
        ...appointment,
        typeTitle: "Appointment",
        created_at: new Date(appointment.createdAt),
        start: new Date(
          appointment.start_datetime || appointment.tentative_date
        ),
        end: appointment.end_datetime
          ? new Date(appointment.end_datetime)
          : new Date(appointment.start_datetime || appointment.tentative_date),
        __typename: type === "Realtor" ? "RealtorAppointment" : "Appointment"
      };
    });
  }

  return appointments;
}

/**
 * This fucntion formats follow-ups data to be shown on details page in calendar and past-activities. This function
 * is used in {@link module:Helpers.formatDataForCalendar}. "Follow-Up" is set as the title for this event and
 * follow-up start end date are set to show this event inside calendar
 * @param {Object} data data to be formatted
 */
function prepareFollowUpsDataForCalendar(data) {
  let followUps = [];
  if (isNotNULL(data.follow_ups) && data.follow_ups.length > 0) {
    followUps = data.follow_ups.map((followUp) => {
      return {
        ...followUp,
        typeTitle: "Follow-Up",
        created_at: new Date(followUp.createdAt),
        start: new Date(followUp.follow_up_datetime),
        end: new Date(followUp.follow_up_datetime),
      };
    });
  }

  return followUps;
}

/**
 * This function formats walk-ins data to be shown on details page in calendar and past-activities. This function
 * is used in {@link module:Helpers.formatDataForCalendar}. "Walk-In" is set as the title for this event and
 * walk-in end date is set to show this event inside calendar
 * @param {Object} data data to be formatted
 */
function prepareWalkInsDataForCalendar(data) {
  let walkIns = [];
  if (isNotNULL(data.walk_ins) && data.walk_ins.length > 0) {
    walkIns = data.walk_ins.map((walkIn) => {
      return {
        ...walkIn,
        typeTitle: "Walk-In",
        start: new Date(walkIn.createdAt),
        end: new Date(walkIn.createdAt),
        created_at: new Date(walkIn.createdAt),
      };
    });
  }

  return walkIns;
}

/**
 * This fucntion formats phone-calls data to be shown on details page in calendar and past-activities. This function
 * is used in {@link module:Helpers.formatDataForCalendar}. Phone call type is set as the title for this event and
 * phonecall datetime is set as start end date to show this event inside calendar. If id is greater than 3 its type
 * is set as Text otherwise PhoneCall.
 * @param {Object} data data to be formatted
 */
function preparePhoneCallsDataForCalendar(data) {
  let phoneCalls = [];
  if (isNotNULL(data.phone_calls) && data.phone_calls.length > 0) {
    phoneCalls = data.phone_calls.map(({ __typename, ...phoneCall }) => {
      return {
        ...phoneCall,
        // title: phoneCall.call_type.name,
        typeTitle: phoneCall.call_type?.name || "NA",
        start: new Date(phoneCall.call_datetime),
        end: new Date(phoneCall.call_datetime),
        __typename: phoneCall.call_type?.id > 3 ? "Text" : "PhoneCall",
      };
    });
  }

  return phoneCalls;
}

/**
 * This fucntion formats emails/mass-emails data to be shown on details page in calendar and past-activities. This
 * function is used in {@link module:Helpers.formatDataForCalendar}. Title is Email Out or Email In, based on the
 * email type. Email datetime is set as start and end date to show event inside calendar. Its type is set as Email or
 * Mass Email based on its "isIndividual" property.
 * @param {Object} data data to be formatted
 */
function prepareEmailsDataForCalendar(data) {
  if (!isNotNULL(data.emails) || !data.emails.length) return [];

  let emails = data.emails.map((item) => {
    return {
      email: item,
      typeTitle: item.email_type === "sent" ? "Email Out" : "Email In",
      start: new Date(item.date_time),
      end: new Date(item.date_time),
      __typename: item.isIndividual ? "Email" : "Mass Email",
    };
  });

  return emails;
}

/**
 * This fucntion formats Eboms data to be shown on details page in calendar and past-activities. This
 * function is used in {@link module:Helpers.formatDataForCalendar}. "Ebomb" is set as the title for this event and
 * email datetime is set as start and end date to show event inside calendar. Its type is set as MailchimpEmailType.
 * @param {Object} data data to be formatted
 */
function prepareMailchimpEmailsDataForCalendar(data) {
  if (!isNotNULL(data.mailchimp_emails) || !data.mailchimp_emails.length)
    return [];

  let mailchimpEmails = data.mailchimp_emails.map((item) => {
    return {
      email: item,
      // title: item.subject,
      typeTitle: "EBomb",
      start: new Date(item.datetime),
      end: new Date(item.datetime),
      __typename: "MailchimpEmailType",
    };
  });

  return mailchimpEmails;
}

/**
 * This function is used to format data of all possible events that are to be shown inside the calendar and
 * past-activities. it combines all the events in an array and sorts them based on their dates.
 * @param {Object} data data to be formatted
 * @param {String} type type of page for which data is being formatted
 * @returns {Array}
 */
export function formatDataForCalendar(data, type) {
  let formattedData = !isNotNULL(data)
    ? []
    : [
      ...prepareSubmissionsDataForCalendar(data, type),
      ...prepareFollowUpsDataForCalendar(data),
      ...prepareAppointmentsDataForCalendar(data, type),
      ...prepareWalkInsDataForCalendar(data),
      ...preparePhoneCallsDataForCalendar(data),
      ...prepareEmailsDataForCalendar(data),
      ...prepareMailchimpEmailsDataForCalendar(data),
    ];

  return formattedData.sort(function (a, b) {
    var dateA = a.start,
      dateB = b.start;
    return dateB - dateA;
  });
}

/**
 * This function is used to format data of all possible events that are to be shown inside the dashboard calendar.
 * It can contain only two types of events: appointments or follow-ups.
 * @param {Object} data data to be formatted
 * @returns {Array}
 */
export const formatDataForDashboardCalendar = (data) => {
  let events = [];

  isNotNULL(data) &&
    data.map((item) => {
      const common_data = {
        done: item.done,
        details: item.details,
        title: item.title,
        first_name_1: item.first_name_1,
        last_name_1: item.last_name_1,
        prospect_id: item.prospect_id,
        lead_id: item.lead_id,
        realtor_id: item.realtor_id,
        isDashBoardCalender: true,
        zoom_meeting_link: item.zoom_meeting_link,
        community: item.community,
      };

      if (item.follow_up_id !== null) {
        events.push({
          ...common_data,
          id: item.follow_up_id,
          typeTitle: "Follow-Up",
          follow_up_datetime: new Date(item.due_date),
          start: new Date(item.due_date),
          end: new Date(item.due_date),
          csm: item.csm,
          icon: <FaCalendarDay />,
          created_at: new Date(item.created_at),
          creator: item.creator,
          __typename: item.prospect_id
            ? "Prospect_Follow_Up"
            : item.lead_id
            ? "Lead_Follow_up"
            : "Realtor_Follow_Up",
        });
      }

      if (item.appointment_id !== null) {
        const appointment = {
          ...common_data,
          id: item.appointment_id,
          typeTitle: "Appointment",
          tentative: item.tentative,
          Appointment_Type: item.Appointment_Type,
          created_at: new Date(item.created_at),
          creator: item.creator,
          __typename: item?.realtor_id ? "RealtorAppointment" :"Appointment",
        };

        item.tentative
          ? events.push({
            ...appointment,
            start: new Date(item.due_date),
            end: new Date(item.due_date),
            tentative_date: new Date(item.tentative_date),
          })
          : events.push({
            ...appointment,
            csm: item.csm,
            start_datetime: new Date(item.due_date),
            end_datetime: new Date(item.end_date),
            start: new Date(item.due_date),
            end: new Date(item.due_date),
          });
      }
    });

  return events;
};

/**
 * This function is used to convert the string to following format "[first character]**********[last character]"
 * based on a boolean parameter.
 * @param {String} value conatains email
 * @param {Boolean} check
 */
export const checkEmailSubscribtion = (value, check) => {
  if (value && check === false) {
    return (
      value.substring(0, 1) +
      "**********" +
      value.substring(value.length - 1, value.length)
    );
  }
  return value;
};

/**
 * This function renders emails and on click email box opens up. If the status of the customer is false then the email
 * link (though clickable) appears disabled with an icon on the left
 * @param {Object} field data containing information
 * @param {String} accessor path to access email
 * @param {Function} callback function to get all emails in a string
 * @param {String} check path to status of the customer
 */
export function checkDisabledEmailsLink(field, accessor, callback, check) {
  //This function does not hide the email but adds a disabled cross at the end of the email
  const value = field[accessor];
  return value ? (
    <a
      href={callback && "mailto:" + callback([field])}
      id="send-action-email"
      className={field[check] === false ? "disabled" : ""}
    >
      {field[check] === false && (
        <img src={UnsubscribedEmailIcon} className="icon-unsubscribe" alt="" />
      )}
      {value + " "}
    </a>
  ) : null;
}

export function phoneLink(field, accessor, clickable = true, wrapper = false) {
  return (
    <PhoneCell
      field={field}
      accessor={accessor}
      clickable={clickable}
      className="details-table-row p-1"
      wrapper={wrapper}
      details={true}
    />
  );
}

/**
 * This function takes a string and returns max-word limit or less
 * @param {String} data string to be truncated
 * @param {Number} words maximum number of words allowed
 */
export function get5WordsForTitle(data, words = 5) {
  if (!data) return "-";
  let Data = data.split(" ");
  if (Data.length > words) {
    let y = Data.slice(0, words).join(" ");
    return y + "...";
  } else {
    let y = data.split(" ").slice(0, words).join(" ");
    return y;
  }
}

/**
 * This function extracts the time from the DateTime object received in props
 * @param {DateTime} date
 * @returns {String}
 */
export function getTimeFromDate(date) {
  return new Date(date).toTimeString().split(":").slice(0, 2).join(":");
}

export function upperCaseFirstLetter(s) {
  return s[0].toUpperCase() + s.slice(1);
}

export function camelCase(value) {
  return value
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getTitlefromPath(value, c) {
  return camelCase(value.toString().slice(c, 40).split("-").join(" "));
}

export const isEmpty = (obj) => {
  return _.isEmpty(obj);
};

export const isObject = (obj) => {
  return obj && typeof obj === "object" && obj.constructor === Object;
};

/**
 * This function is used to sort any array in the order required
 * @param {Array} arr Array of objects to be sorted
 * @param {String} accessor The accessor to get the variable on which to sort e.g. name, email, etc.
 * @param {String} order The order in which to sort: accepts either 'asc' for ascending order or 'desc' for descending order
 */
export const sortArray = (arr, accessor, order) => {
  return arr.sort((a, b) => {
    if (a[accessor] > b[accessor]) {
      return order === "asc" ? 1 : order === "desc" ? -1 : 0;
    }
    if (b[accessor] > a[accessor]) {
      return order === "asc" ? -1 : order === "desc" ? 1 : 0;
    }
    return 0;
  });
};

/**
 * This function is used to format string into following format "(703) 967-0631"
 * @param {String} cell cell string containing 10 characters
 * @returns {String}
 */
export const phoneDisplay = (cell) => {
  if (!cell) return "";

  if (cell.length > 0 && cell.length <= 3) return cell;

  if (cell.length > 3 && cell.length <= 6)
    return "(" + cell.slice(0, 3) + ") " + cell.slice(3, 6);

  return (
    "(" +
    cell.slice(0, 3) +
    ")" +
    " " +
    cell.slice(3, 6) +
    "-" +
    cell.slice(6, 10)
  );
};

/**
 * This function checks if the data contains any information related to buyer 2
 * @param {Object} data
 */
export const isBuyerTwo = (data) => {
  return (
    data.first_name_2 ||
    data.last_name_2 ||
    data.primary_email_2 ||
    data.cell_phone_2
  );
};

/**
 * It takes two dates and compares their months if the first date is before or after the second date and stores
 * results in an object and returns it
 * @param {String} firstDate
 * @param {String} secondDate
 * @returns {{isBefore: Boolean, isAfter:Boolean}}
 */
export const MonthYearComparison = (firstDate, secondDate) => {
  let toReturn = { isBefore: false, isAfter: false };
  toReturn.isBefore = moment(firstDate).isBefore(moment(secondDate), "month");
  toReturn.isAfter = moment(firstDate).isAfter(moment(secondDate), "month");
  return toReturn;
};

/**
 * This function checks if duedate has passed or not
 * @param {Object} row
 * @returns {Boolean}
 */
export const isDateOverdue = (row) => {
  if (!row.due_date) return false;
  return row.follow_up_id
    ? moment(new Date(row.due_date)).isBefore(new Date(), "day")
    : moment(new Date(row.due_date)).isBefore(new Date());
};

/**
 * @returns {Array} An array of weekdays as strings
 */
export const getWeekdays = () => {
  return moment.weekdays();
};

export const isToday = (someDate) => {
  return moment(someDate).isSame(new Date(), "day");
};

export const isTodaySunday = () => {
  return moment().day() === 0;
};
export const thisMonday = () => {
  if (isTodaySunday()) {
    return moment().day(-6).format("YYYY/MM/DD");
  } else {
    return moment().day("monday").format("YYYY/MM/DD");
  }
};
export const thisSunday = () => {
  if (isTodaySunday()) {
    return moment().day(0).format("YYYY/MM/DD");
  } else {
    return moment().day(7).format("YYYY/MM/DD");
  }
};

export const thisMondayDate = () => {
  if (isTodaySunday()) {
    return moment(new Date().toDateString()).day(-6).toDate().toISOString();
  } else {
    return moment(new Date().toDateString())
      .day("monday")
      .toDate()
      .toISOString();
  }
};

export const thisSundayDate = () => {
  if (isTodaySunday()) {
    return moment().day(0).toDate().toISOString();
  } else {
    return moment().day(7).toDate().toISOString();
  }
};

export const getLastYearMonday = () => {
  const currentYear = new Date().getFullYear();

  if (isTodaySunday()) {
    return moment()
      .day(-6)
      .set("year", currentYear - 1)
      .format("YYYY/MM/DD");
  } else {
    return moment()
      .day("monday")
      .set("year", currentYear - 1)
      .format("YYYY/MM/DD");
  }
};

export const getLastYearSunday = () => {
  const currentYear = new Date().getFullYear();

  if (isTodaySunday()) {
    return moment()
      .day(0)
      .set("year", currentYear - 1)
      .format("YYYY/MM/DD");
  } else {
    return moment()
      .day(7)
      .set("year", currentYear - 1)
      .format("YYYY/MM/DD");
  }
};

/**
 * It takes in filter type and creates a filter object accordingly.
 * @param {String} filterType it can be today or week or prev_year_week
 * @returns {Object} object containing start and end date or empty object
 */
export const getCreateDateFilters = (filterType) => {
  const filter = {};

  switch (filterType) {
    case 'today':
      filter.create_start_date = getDate_yyyymmdd_format();
      filter.create_end_date = getDate_yyyymmdd_format();
      break;
    case 'week':
      filter.create_start_date = moment().isoWeekday(1).format("YYYY/MM/DD");
      filter.create_end_date = moment().format("YYYY/MM/DD");
      break;
    case 'month':
      filter.create_start_date = moment().startOf("month").format("YYYY/MM/DD");
      filter.create_end_date = moment().format("YYYY/MM/DD");
      break;
    case 'year':
      filter.create_start_date = moment().startOf("year").format("YYYY/MM/DD");
      filter.create_end_date = moment().format("YYYY/MM/DD");
      break;
    case 'prev_year_month':
      filter.create_start_date = moment().startOf("month").set("year", new Date().getFullYear() - 1).format("YYYY/MM/DD");
      filter.create_end_date = moment().set("year", new Date().getFullYear() - 1).format("YYYY/MM/DD");
      break;
    case 'prev_year':
      // This function brings the start of the previous year to the current date of the last year
      filter.create_start_date = moment().startOf("year").set("year", new Date().getFullYear() - 1).format("YYYY/MM/DD");
      filter.create_end_date = moment().set("year", new Date().getFullYear() - 1).format("YYYY/MM/DD");
      break;
    case 'prev_year_complete':
      // This function sets the date from the start of the previous year to the end
      filter.create_start_date = moment().startOf("year").set("year", new Date().getFullYear() - 1).format("YYYY/MM/DD");
      filter.create_end_date = moment([new Date().getFullYear() - 1, 11, 31]);
      break;
    case 'prev_week':
      filter.create_start_date = moment().isoWeekday(1).subtract(7, "days").format("YYYY/MM/DD");
      filter.create_end_date = moment().isoWeekday(7).subtract(7, "days").format("YYYY/MM/DD");
      break;
    case 'prev_month':
      filter.create_start_date = moment().startOf("month").set("month", new Date().getMonth() - 1).format("YYYY/MM/DD");
      filter.create_end_date = moment().endOf("month").set("month", new Date().getMonth() - 1).format("YYYY/MM/DD");
      break;
    case 'trailing_year':
      filter.create_start_date = moment().set("year", new Date().getFullYear() - 1).set("day", new Date().getDay() - 1).format("YYYY/MM/DD");
      filter.create_end_date = moment().format("YYYY/MM/DD");
  }

  return filter;
};

export const sanitizeFilterObject = (filter) => {
  for (let propName in filter) {
    if (
      (Array.isArray(filter[propName]) &&
        filter[propName].every((el) => el === null || el === undefined)) ||
      (isObject(filter[propName]) && !filter[propName].id) ||
      (filter[propName] !== false && !filter[propName]) ||
      propName === "advanceFilters"
    ) {
      delete filter[propName];
    }
  }

  return filter;
};

/**
 * This function takes in two dates and checks if first date is earlier or before than the second date. It only
 * checks their time fields. Equality also returns true
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {Boolean}
 */
export const checkIsBeforeTime = (startTime, endTime) => {
  if (startTime === endTime) return true;
  const startingTime = moment(startTime).format(DATE_TIME_FORMAT_24_HOURS);
  const endingTime = moment(endTime).format(DATE_TIME_FORMAT_24_HOURS);
  return startingTime < endingTime;
};

/**
 * This function takes in a date and checks if it is earlier than current date. It only check their time fields.
 * Equality also returns true
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {Boolean}
 */
export const checkIsBeforeCurrentTime = (startTime, endTime) => {
  if (startTime === new Date()) return true;
  const currentTime = new Date();
  const startingTime = new Date(startTime);
  return startingTime < currentTime;
  // remove return statement above and uncomment return statement below in case of appt times exceeding current day (post 12am)
  // return !(startingTime < currentTime) || formatDate(endTime) > formatDate(new Date());
};

/**
 * It takes in three dates and checks if a date lies in between a date range or not
 * @param {*} toCheck  date that we want to check
 * @param {Date} a start date
 * @param {Date} b end date
 * @returns {Boolean}
 */
export const isDateInRange = (toCheck, a, b) => {
  if (toCheck === "" || !toCheck) return true;
  const start = moment(a).format("YYYY-MM-DD");
  const end = moment(b).format("YYYY-MM-DD");
  const check = moment(toCheck).format("YYYY-MM-DD");
  return start <= check && check <= end;
};

/**
 * It checks if two dates are equal
 * @param {*} toCheck
 * @param {Date} a start date
 */
export const isDateValid = (toCheck, a) => {
  if (toCheck === "" || !toCheck) return true;
  const start = moment(a).format("YYYY-MM-DD");
  const check = moment(toCheck).format("YYYY-MM-DD");
  return start === check;
};

/**
 * A function that adds the number of days to given date
 * @param {DateTime} date date to which we add the days
 */
export const addDays = (date, days) => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const addTimeToDate = (date, duration, type) => {
  return moment(date).add(duration, type);
};

export const goBack = (history) =>
  history.length === 1 ? history.replace("/") : history.goBack();

export const getCommunityIds = (selectedCommunity) => {
  return selectedCommunity.selectedCommunityIds.ids.filter((e) => e !== -1);
};

export const getActiveCommunities = (allCommunities) => {
  return allCommunities
    ? allCommunities.filter((community) => community.is_active)
    : [];
};

export const filterCommunitiesByDivisions = (allCommunities, divisions) => {
  return divisions?.length
    ? allCommunities?.filter(
      (community) =>
        community.is_active &&
        divisions.find((division) => division === community.division_id)
    )
    : [];
};

export const getIssueEmail = () => {
  const subject = `Bug/Change request/Feature request - (${moment().unix(0)})`;
  const body =
    "Description: \n\nPlease specify the steps that caused the issue: \n\nPriority level: Urgent? High? Medium? Low? Future enhancement? \n\nActual results: \n\nExpected results: \n\nRole you were signed in as: CSM? OSC? Manager? \n\nScreenshot: \n\n\n";

  return `mailto:${process.env.REACT_APP_BUG_EMAIL
    }?Subject=${encodeURIComponent(subject)}&Body=${encodeURIComponent(body)}`;
};

/**
 * This function takes in a time string and decides whether to put AM or PM after it
 * @param {String} time its of the following format "12:30"
 */
export const formatAMPM = (time) => {
  var hours = time.split(":")[0];
  var minutes = time.split(":")[1];
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : ampm === "PM" ? 12 : 0; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

/**
 * This function is a small component that renders communities along with a ban icon if they are not active
 * @param {Data} field
 * @param {Boolean} showComma
 */
export const inActiveMapper = (field, showComma) => {
  return field.map((item, index) => (
    <div key={index} className="d-flex align-items-center">
      {item && item.is_active === false && <FaBan className="td-warning" />}
      {item ? item.name : "-"}
      {showComma && index !== field.length - 1 && ","}&nbsp;
    </div>
  ));
};

/**
 * This function is a small component which is used for communities and csms to show their names along with ban icon
 * based on their active status.
 * @param {Object} field data containing is_active information
 * @param {String} value text to be rendered after icon
 * @param {Boolean} isCurrent whether to show (current) after value or not
 * @param {Number} size size of icon
 */
export const InactivityIcon = (field, value, isCurrent, size = 10) => {
  return (
    <>
      <div className="d-flex align-items-center">
        {field && field.is_active === false && (
          <FaBan size={size} className="td-warning" />
        )}
        <span style={{ fontSize: "inherit" }}>{value}</span>
        {isCurrent && <span className="caption"> (current) </span>}
      </div>
    </>
  );
};

/**
 * This function creates a dictionary of values inside objects and creates new array containing single entry of every
 * object
 * @param {Array} originalArray array containing duplicates
 * @param {String} prop property on which basis duplicates shoul be removed
 */
export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject = {};

  originalArray &&
    originalArray.map((item) => {
      if (item && !lookupObject[item[prop]]) lookupObject[item[prop]] = item;
      return item;
    });

  for (let key of Object.keys(lookupObject)) {
    newArray.push(lookupObject[key]);
  }

  return newArray;
};

export const defaultTimeList = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const getUpdatedParams = (oldCards, newCard) => {
  let updatedCards = Object.assign([], { ...oldCards });
  let foundNode = false;

  updatedCards.length > 0 &&
    updatedCards.forEach((node, index) => {
      if (newCard && newCard.cardTitle === node.cardTitle) {
        updatedCards[index].params = newCard.params;
        foundNode = true;
      }
    });

  if (!foundNode) {
    updatedCards.push(newCard);
  }
  return updatedCards;
};

/**
 * This function is used to find object from the array containing cardTitle equal to title
 * @param {String} title title to be found
 * @param {Array} cards array of cards
 */
export function getCardParams(title, cards) {
  let foundCard =
    cards &&
    cards.cardsParams
      .filter((val) => val.cardTitle === title)
      .find((item) => item);
  return (foundCard && foundCard.params) || null;
}

/**
 * This function creates a list of options for scores from 1-10 and associates colors with it red/orange/green. And if
 * inverseColor is passed as true opposite colors are assigned.
 * @param {Boolean} inverseColor
 */
export const getScoreColorList = (inverseColor) => {
  const firstlistElementStyle = inverseColor ? "rating-green" : "rating-red";
  const lastlistElementStyle = inverseColor ? "rating-red" : "rating-green";

  let listStyle = [
    { style: firstlistElementStyle, color: inverseColor ? "green" : "red" },
    { style: firstlistElementStyle, color: inverseColor ? "green" : "red" },
    { style: firstlistElementStyle, color: inverseColor ? "green" : "red" },
    { style: firstlistElementStyle, color: inverseColor ? "green" : "red" },
    { style: "rating-orange", color: "orange" },
    { style: "rating-orange", color: "orange" },
    { style: "rating-orange", color: "orange" },
    { style: lastlistElementStyle, color: inverseColor ? "red" : "green" },
    { style: lastlistElementStyle, color: inverseColor ? "red" : "green" },
    { style: lastlistElementStyle, color: inverseColor ? "red" : "green" },
  ];

  listStyle = listStyle.map((item, index) => {
    return {
      ...item,
      value: index + 1,
      eventKey: index + 1,
    };
  });

  listStyle = [
    { value: "Score", eventKey: 0 },
    { value: "?", eventKey: -1 },
    ...listStyle,
  ];
  return listStyle;
};

export const getMwcList = () => {
  return [
    { eventKey: null, value: "Select..." },
    { eventKey: true, value: "Yes" },
    { eventKey: false, value: "No" },
  ];
};

/**
 * This function is a component used to preview the email body in a separate popover
 * @param {Object} data
 * @param {Date} NewDate
 * @param {String} csmNames
 * @param {Function} onClick
 */
export const emailPreviewBody = (data, NewDate, csmNames, onClick) => {
  return (
    <div className="dialog-email-details">
      <div className="email-preview-btn">
        <Button
          className="btn-close"
          color="white"
          style={{ fontSize: "16px", color: "white" }}
          onClick={onClick}
        >
          <FaTimes />
        </Button>
      </div>

      <div className="email-row">
        <div className="d-flex justify-content-between align-items-center">
          <div className="relative-position">
            <div
              className="email-title-small"
              style={{ color: "rgb(255, 122, 0, 1)" }}
            >
              <FaEnvelope className="mr-1" /> EMAIL
            </div>
            <h3 className="m-0 mt-1 email-title">{data.title}</h3>
          </div>
        </div>
      </div>

      <div className="email-data-table">
        <ul className="event-details-list">
          <li>
            <label>Subject</label>
            <span>{data.subject}</span>
          </li>
          <li>
            <label>Date</label>
            <span>{NewDate}</span>
          </li>
          <li>
            <label>Type</label>
            <span style={{ textTransform: "capitalize" }}>
              {data.email_type}
            </span>
          </li>
          <li>
            <label>BY:</label>
            <span>{csmNames?.join(", ")}</span>
          </li>
        </ul>
      </div>

      <div className="email-content">
        <span
          className="email-message"
          dangerouslySetInnerHTML={{
            __html: data.body,
          }}
        ></span>
      </div>
    </div>
  );
};

/**
 * This is a generic list of icons that are to be used with events on calendar and past-activities
 */
const iconsList = [
  { title: "Text Out", Icon: TextOut, type: "svg" },
  { title: "Text In", Icon: TextIn, type: "svg" },
  { title: "Call Out", Icon: CallOut, type: "svg" },
  { title: "Call In", Icon: CallIn, type: "svg" },
  { title: "Email Out", Icon: EmailOut, type: "svg" },
  { title: "Email In", Icon: EmailIn, type: "svg" },
  { title: "Appointment", Icon: FaCalendarCheck, shortNotation: "Appt" },
  { title: "Follow-Up", Icon: FaCalendarDay },
  { title: "Web Form", Icon: FaListAlt },
  { title: "EBomb", Icon: FaEnvelope },
  { title: "Left Voicemail", Icon: FaPhone, shortNotation: "Voicemail" },
  { title: "Walk-In", Icon: FaHome },
];

/**
 * This function finds the title in the icons list and returns that specific icon
 * @param {String} title
 */
export const getIconByEvent = (title) => {
  const result = iconsList.find((item) => item.title === title);
  if (!result) return null;
  return result.type === "svg" ? (
    <img
      src={result.Icon}
      alt={result.title}
      height="14px"
      width="14px"
      style={{ marginRight: "4px" }}
    />
  ) : (
    <result.Icon size={11} style={{ marginRight: "4px" }} />
  );
};

export const getShortNotation = (title) => {
  const result = iconsList.find((item) => item.title === title);
  return (result && result.shortNotation) || title;
};

/**
 * This function checks if url conains http/https or not. If it does not conatins it then append http to it and
 * return
 * @param {String} url
 * @returns {String}
 */
export const addHttpToUrl = (url) => {
  var re = new RegExp("^(http|https)://", "i");
  var match = re.test(url);
  return !match ? "http://" + url : url;
};

/**
 * This function splits the string by '\n' and renders all the item on individual lines
 * @param {String} data
 */
export const splitIntoFields = (data) => {
  if (!data) return null;

  let returnValue = data.split("\n");
  return returnValue.map((item, index) => (
    <div
      key={index}
      className="d-flex flex-column"
      dangerouslySetInnerHTML={{ __html: item }}
    />
  ));
};

/**
 * This function returns clickable secondary emails in the form of a listPopup
 * @param {Object} data Data containing the secondary emails
 * @param {String} name The accessor to obtain secondary emails from data
 * @param {Function} callback Function to get all emails in a string
 * @param {String} accessor The accessor to obtain secondary emails if it exists in an array of objects (Optional)
 * @param {Boolean} clickable Boolean to specify whether the secondary emails should be clickable or not (By default emails are clickable)
 * @return {JSX.Element} This function returns clickable secondary emails in the form of list popup
 */
export const handleSecondaryEmails = (
  data,
  name,
  callback,
  accessor,
  clickable = true
) => {
  return (
    <ListPopup
      data={data[name]}
      mapper={(field) =>
        field.map((item, index) => (
          <div key={index}>
            {clickable ? (
              <a href={callback && `mailto:${callback([data])}`}>
                {accessor ? item[accessor] : item}
              </a>
            ) : (
              <p>{accessor ? item[accessor] : item}</p>
            )}
          </div>
        ))
      }
    />
  );
};

/**
 * This callback function returns all the subscribed emails from the data provided
 * @param {Object} data Data containing the emails
 */
export const getSubscribedEmails = (data) => {
  let emails = "";
  data.forEach((x) => {
    emails = [
      emails,
      x.secondary_emails_1?.join("%3B%20"),
      x.secondary_emails_2?.join("%3B%20"),
      x.primary_email_1_status && x.primary_email_1,
      x.primary_email_2_status && x.primary_email_2,
    ]
      .filter(Boolean)
      .join("%3B%20");
  });

  return emails;
};

/**
 * This callback function returns all the emails from the data provided
 * @param {Object} data Data containing the emails
 */
export function getAllEmails(data) {
  let emails = "";
  data.forEach((x) => {
    emails = [
      emails,
      x.secondary_emails_1?.join("%3B%20"),
      x.secondary_emails_2?.join("%3B%20"),
      x.primary_email_1,
      x.primary_email_2,
    ]
      .filter(Boolean)
      .join("%3B%20");
  });
  return emails;
}

export const formatCallDuration = (call_duration) => {
  return Math.ceil(call_duration / 60);
};

export const formatCallDurationsWithSeconds = (call_duration) => {
  const callMinutes = Math.floor(call_duration / 60);

  if (callMinutes < 1) return `${call_duration % 60} sec(s)`;
  return `${callMinutes} min(s) ${call_duration % 60} sec(s)`;
};

export const removeElement = (array, index) => {
  const arr = _.cloneDeep(array);
  arr.splice(index, 1);
  return arr;
};

export const filterArray = (array, filterFunc) => {
  return _.uniqWith(array, filterFunc);
};

export const redirectToDashboard = (location) => {
  location.assign(location.href.split("/").slice(0, 3).join("/"));
};
