export const getInitialState = ({ loading, cache }) => {
  return {
    filter: {
      ...(cache && cache.filter)
    },
    search: (cache && cache.search) || "",
    limit: (cache && cache.limit) || 10,
    pageNum: (cache && cache.pageNum) || 0,
    pageSize: (cache && cache.limit) || 10,
    order: (cache && cache.order) || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    },
    userPageCount: 0,
    tasks: [],
    loading: loading,
    render: !loading,
    showCsmDialog: false,
    csmId: undefined,
    disableChange: true,
    showMarkDoneConfirmation: false,
    showConfirmation: false,
    appointment_ids: [],
    lead_appointment_ids: [],
    prospect_follow_up_ids: [],
    lead_follow_up_ids: [],
    realtorAppointmentIds: [],
    selectedCommunityId: null
  };
};

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, { ...action.payload });

    default:
      return state;
  }
}
