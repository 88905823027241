import gql from 'graphql-tag';

const UPDATE_REALTOR_FOLLOW_UP = gql` 
  mutation updateRealtorFollowUp($input: RealtorFollowUpUpdateInput!) {
    updateRealtorFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_REALTOR_FOLLOW_UP;