import gql from 'graphql-tag';

const MARK_REALTOR_APPOINTMENT_DONE = gql` 
mutation markDoneRealtorAppointment($input: RealtorAppointmentMarkDoneInput!) {
    markDoneRealtorAppointment(input: $input){
    message
    code
    }
}
`;

export default MARK_REALTOR_APPOINTMENT_DONE;
