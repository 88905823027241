import React, { useState, useEffect } from "react";
import { Form, Field, withFormik, FieldArray } from "formik";
import { ButtonToolbar } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import {
  scheduleAppointmentForm,
  scheduleAppointmentSchema,
} from "./schedule-appointment-form-schema";
import TextField from "../../../components/fields/text-field";
import SelectField from "../../../components/fields/select-field";
import DatePickerView from "../../../components/fields/date-picker";
import CheckboxField from "../../../components/fields/checkbox-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";

import { GET_CSM_AVAILABILITY_TIMESLOTS } from "../../../graphql/queries";

import { DefaultQuery } from "../../../graphql/default-query";

import "./lead-form-styles.css";
import { isNotNULL, formatDate } from "../../../utils/helpers";
import LoadingWrapper from "../../../components/loading-wrapper";
import ScheduleChart from "../../../components/schedule-chart/schedule-chart";
import { appointmentTypeDurations } from "../../../utils/constants";

/**
 * This component is used to render schedule appointment form for lead and then converts it to prospect. It contains text field as well as select, time, date, text area select fields.
 * @param {Object} props
 */
const AppointmentForm = (props) => {
  const { values, initialValues, getLoggedInUserCommunity } = props;

  const [timeFilterType, setTimeFilterType] = useState('AM')

  const getSelectOptions = (allCommunities, values, index, label) => {
    return (
      <optgroup label={label}>
        {allCommunities.map((community, communityIndex) => {
          let communityAlreadySelected = false;
          for (let i = 0; i < values.input.length; i++) {
            if (i !== index) {
              if (values.input[i].community_id === community.id) {
                communityAlreadySelected = true;
                break;
              }
            }
          }
          return (
            community.is_active && (
              <option
                key={communityIndex}
                value={`${community.id}__${community.division_id}`}
                disabled={communityAlreadySelected}
                className={communityAlreadySelected ? "my-options" : ""}
              >
                {community.name}
              </option>
            )
          );
        })}
      </optgroup>
    );
  };

  const handleTimeSlotSelection = ({ meetingStartDateTime, meetingEndDateTime }, csm_data, index) => {
    props.setFieldValue(`input[${index}].csm_id`, csm_data.id)
    props.setFieldValue(`input[${index}].start_datetime`, new Date(meetingStartDateTime));
    props.setFieldValue(`input[${index}].end_datetime`, new Date(meetingEndDateTime))
  }

  const availableCommunities =
    getLoggedInUserCommunity.getLoggedInUserCommunities &&
    getLoggedInUserCommunity.getLoggedInUserCommunities.filter((item) => {
      return !props.leadData.communities.find((Item) => {
        return item.id === Item.id;
      });
    });

  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div
          className="d-md-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Lead</div>
            <div className="form_TitleBig mt-1">
              <h2>Schedule Appointment</h2>
            </div>
          </div>

          <div className="d-md-flex mt-2 mt-md-0">
            <ButtonToolbar>
              <CustomButton
                color="dark"
                className={`form_CancelButton`}
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type='submit'
                disabled={props.isSubmitting}
                btnValue={props.function || "Schedule & Convert"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>

      <div className="activity-form form_body">
        <div className="mb-3">
          <div className="">
            <h5>Appointment(s)</h5>
          </div>
        </div>

        <FieldArray
          name="input"
          render={(arrayHelpers) => (
            <div>
              {values.input &&
                typeof Array.isArray(values.input) &&
                values.input.map(({ community_id, ...pr }, index) => (
                  <div className="form-community" key={index}>
                    <div className="row form_deleteButtonRow">
                      <CustomButton
                        className="icon-btn btn-icon"
                        onClick={() =>
                          values.input.length > 1 && arrayHelpers.remove(index)
                        }
                      >
                        <FaTimes size={14} style={{ color: "white" }} />
                      </CustomButton>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <Field
                          label="Title"
                          name={`input[${index}].title`}
                          id="title"
                          type="text"
                          required
                          component={TextField}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-4">
                        <Field
                          label="Appointment Type"
                          isString
                          name={`input[${index}].appointment_type_id`}
                          id={`input[${index}].appointment_type_id`}
                          required
                          component={SelectField}
                        >
                          <option value="">Select...</option>
                          {props.getAllAppointmentTypes &&
                            isNotNULL(
                              props.getAllAppointmentTypes
                                .getAllAppointmentTypes
                            )
                            ? props.getAllAppointmentTypes.getAllAppointmentTypes.map(
                              (appointmentType) => (
                                <optgroup label={appointmentType.name}>
                                  {appointmentTypeDurations[appointmentType.name].map((duration, index) => (
                                    <option key={index} value={`${appointmentType.id}__${duration}`}>
                                      {`${duration >= 60 ? `${duration / 60} hour${duration > 60 ? 's' : ''}` : `${duration} minutes`}`}
                                    </option>
                                  ))}
                                </optgroup>
                              )
                            )
                            : null}
                        </Field>
                      </div>
                      <div className="col-md-6">
                        <Field
                          name={`input[${index}].community_id`}
                          label="Community"
                          id="schedule-appointment"
                          required
                          isString
                          disabled={
                            !props.leadData?.communities?.length &&
                              !availableCommunities?.length
                              ? true
                              : false
                          }
                          component={SelectField}
                        >
                          <option value="">Select...</option>
                          {props.leadData.communities?.length &&
                            getSelectOptions(
                              props.leadData.communities,
                              values,
                              index,
                              "Lead Communities"
                            )}
                          {availableCommunities?.length &&
                            getSelectOptions(
                              availableCommunities,
                              values,
                              index,
                              "Other Communities"
                            )}
                        </Field>
                      </div>
                      <div className="ml-1" style={{ maxWidth: '110px' }}>
                        <Field
                          label="Date"
                          name={`input[${index}].appointment_datetime`}
                          id="appointment_datetime"
                          required
                          minDate={new Date()}
                          value={values.input[index].appointment_datetime}
                          component={DatePickerView}
                        />
                      </div>
                    </div>
                    {values.input[index].appointment_type_id && values.input[index].community_id && (
                      <div className="row">
                        <div className="col-md-12">
                          <DefaultQuery
                            query={GET_CSM_AVAILABILITY_TIMESLOTS}
                            variables={{
                              filter: { community_id: parseInt(values.input[index].community_id.split('__')[0]) },
                              duration: parseInt(values.input[index].appointment_type_id.split('__')[1]),
                              appointment_date: formatDate(values.input[index].appointment_datetime),
                            }}
                            fetchPolicy="network-only"
                          >
                            {({ data, loading, error }) => {
                              return !error ? (
                                <div className="backdrop-dark">
                                  <LoadingWrapper
                                    loading={loading}
                                    component={
                                      <ScheduleChart
                                        data={data?.getCsmAvailabilityTimeSlots || []}
                                        loading={loading}
                                        value={{ startTime: values.input[index].start_datetime, endTime: values.input[index].end_datetime, csm_id: values.input[index].csm_id }}
                                        timeFilter={timeFilterType}
                                        setTimeFilterType={setTimeFilterType}
                                        handleSelection={handleTimeSlotSelection}
                                        errors={props.errors.input && props.errors.input[index] ? props.errors.input[index].csm_id || props.errors.input[index].startTimeCheck : null}
                                        index={index}
                                        communityId={parseInt(values.input[index].community_id.split('__')[0])}
                                      />
                                    }
                                  />
                                </div>
                              ) : null
                            }}
                          </DefaultQuery>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-3">
                        <Field
                          id="send_email"
                          label="Send Email"
                          name={`input[${index}].send_email`}
                          customOnChange={(value) => {
                            props.setFieldValue(`input[${index}].send_email`, value);
                          }}
                          component={CheckboxField}
                        />
                      </div>
                    </div>
                    <ErrorFocus
                      names={[
                        {
                          fieldName: `input[${index}].start_datetime`,
                          errorName: `input[${index}].startTimeCheck`,
                        },
                        {
                          fieldName: `input[${index}].end_datetime`,
                          errorName: `input[${index}].timecheck`,
                        },
                      ]}
                    />
                  </div>
                ))}

              <div className="form-addCommunity">
                <CustomButton
                  color="black"
                  disabled={
                    getLoggedInUserCommunity.getLoggedInUserCommunities &&
                    values.input.length ===
                    getLoggedInUserCommunity.getLoggedInUserCommunities
                      .length +
                    props.leadData.communities.length
                  }
                  className={"form_addCommunityButton"}
                  onClick={() => arrayHelpers.push(initialValues.input[0])}
                >
                  Add Appointment
                </CustomButton>
              </div>
            </div>
          )}
        />
      </div>
    </Form>
  );
};

function setInputs(values, props) {
  let scheduleAppointments = values.input.map((appointment) => {
    let {
      appointment_datetime,
      start_datetime,
      end_datetime,
      community_id,
      appointment_type_id,
      ...rest
    } = appointment;

    return {
      ...({
        start_datetime: start_datetime.toUTCString(),
        end_datetime: end_datetime.toUTCString(),
      }),
      community_id: parseInt(community_id.split('__')[0]),
      appointment_type_id: parseInt(appointment_type_id.split('__')[0]),
      ...rest,
    };
  });

  return { lead_id: props.leadId, scheduleAppointments };
}

export default withFormik({
  mapPropsToValues: (props) => ({ ...scheduleAppointmentForm }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(Object.assign({}, values), props);
    props
      .scheduleAndConvertLead({
        variables: {
          input,
        },
      })
      .then((res) => {
        setSubmitting(false);
        if (res.data.scheduleAndConvertLead.code === 200) {
          props.onSubmit(res.data.scheduleAndConvertLead.message);
        }
      });
  },
  validationSchema: scheduleAppointmentSchema,
  displayName: "schedule-appointment-form",
})(AppointmentForm);
