import gql from 'graphql-tag';

const MARK_REALTOR_FOLLOW_UP_DONE = gql` 
mutation markDoneRealtorFollowup($input: RealtorFollowUpMarkDoneInput!) {
  markDoneRealtorFollowup(input: $input){
      message
      code
    }
  }
`;

export default MARK_REALTOR_FOLLOW_UP_DONE;