import gql from 'graphql-tag';

const ADD_REALTOR_APPOINTMENT = gql` 
  mutation addRealtorAppointment($input: RealtorAppointmentInput!) {
    addRealtorAppointment(input: $input) {
      message
      code
    }
  }
`;

export default ADD_REALTOR_APPOINTMENT;