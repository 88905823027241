import gql from 'graphql-tag';

const ADD_REALTOR_FOLLOW_UP = gql` 
  mutation addRealtorFollowUp($input: RealtorFollowUpInput!) {
    addRealtorFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default ADD_REALTOR_FOLLOW_UP;