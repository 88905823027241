import gql from 'graphql-tag';

const GET_REALTOR_APPOINTMENTS_FOLLOWUPS = gql`
query getAllRealtorAppointmentsAndFollowups(
    $limit: Int
    $pageNum: Int
    $filter: AppointmentsAndFollowupsFilter
    $search: String
    $order: String
  ) {
    getAllRealtorAppointmentsAndFollowups(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      order: $order
    ) {
      Realtor_Appointment_And_Followup {
        realtor_id
        done
        appointment_id
        follow_up_id
        first_name
        last_name
        primary_email
        cell_phone
        zoom_meeting_link
        title
        details
        due_date
        end_date
        created_at
        creator {
          first_name
          last_name
        }
        csm {
          id
          first_name
          last_name
          is_active
        }
        community {
          id
          name
          is_active
        }
        Appointment_Type {
          id
          name
        }
      }
      total_count
    }
  }
`
export default GET_REALTOR_APPOINTMENTS_FOLLOWUPS;