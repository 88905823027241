import gql from "graphql-tag";

const GET_REALTOR_DETAILS = gql`
  query GetRealtors(
    $id: Int
    $limit: Int
    $pageNum: Int
    $filter: RealtorFilter
    $order: [String]
  ) {
    getRealtors(
      id: $id
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      order: $order
    ) {
      realtors {
        id
        first_name
        last_name
        primary_email
        primary_email_status
        Agency{
          name
          address
          city
          state{
            id
            name
          }
          zip
        }
        cell_phone
        is_listing_agent_of_sb
        sales {
          client_name
          community {
            id
            name
            is_active
          }
          sale_date
          lot
          registration_date
        }
        registrations {
          client_name
          client_email
          client_email_status
          client_phone
          date
        }
        Realtor_Other_Emails {
          email_address
        }
        Communities {
          id
          name
          is_active
        }
        division {
          id
          name
        }
        notes {
          id
          description
          createdAt
          updatedAt
          creator {
            id
            first_name
            last_name
          }
          note_attachment {
            id
            file_path
            createdAt
            updatedAt
          }
        }
        sales_count
        registration_count
        appointments {
          id
          title
          details
          done
          send_email
          zoom_meeting_link
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          Appointment_Type{
            id
            name
          }
          community {
            id
            name
          }
          division {
            id
            name
          }
          start_datetime
          end_datetime
        }
        follow_ups {
          id
          title
          details
          done
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          community {
            id
            name
          }
          follow_up_datetime
        }
      }
    }
  }
`;

export default GET_REALTOR_DETAILS;
