import React from "react";
import { useState } from "react";
import CustomButton from "../../components/custom-button";
import Drawer from "../../hoc/Drawer";

/**
* This component is used to render the activity button and the activity drawer.
* Once the button is clicked the create edit activity drawer will show.
* 
* @param {*} props
* @param {object} props.data provides all information related to the activities
* @param {string} props.type specifies type of customer
* @param {string} props.function specifies type of function if its create or edit
* @param {object} props.btnIcon specifies icon of the button
* @param {component} props.container specifies the activity container
* @param {number} props.community_id specifies the customers communities 
*/

const ActivityButton = ({ component: Component, ...props }) => {
  const [showSidebar, setShowSidebar] = useState(false)

  const toggleShowDrawer = () => {
    if (props.function === "UPDATE") {
      props.close(null);
    } else {
      setShowSidebar(!showSidebar)
    }
  }

  const onSubmit = message => {
    setShowSidebar(false)
    if (props.function === "UPDATE") {
      props.close();
    }
    props.onSubmit(message);
  }

  return (
    <>
      {props.function === "CREATE" && (
        <CustomButton
          btnValue={"ACTIVITY"}
          btnIcon={props.btnIcon}
          onClick={toggleShowDrawer}
        />
      )}

      <Drawer
        show={showSidebar || props.function !== "CREATE"}
        toggleSideBar={toggleShowDrawer}
      >
        <Component
          close={toggleShowDrawer}
          community_id={
            props.data ?
              props.data.community ?
                props.data.community.id :
                props.data.communities?.length ?
                  props.data.communities : []
              : props.community_id
          }
          division_id={props.data?.divisions 
            ? props.data?.divisions 
            : props.data?.division 
            ? [props.data?.division] 
            : null}
          id={props.data?.id ?? props.item?.id}
          function={props.function}
          onSubmit={onSubmit}
          type={props.type}
          item={props.function === "CREATE" ? null : props.item ?? null}
          editForm={props.function === "CREATE" ? false : true}
          hasRealtor={props.data?.realtor?.id}
        />
      </Drawer>
    </>
  );
}

export default ActivityButton;