import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import { NavLink } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaPen,
  FaCheck,
  FaTrash,
  FaCalendarDay,
  FaEnvelope,
  FaPaperclip,
  FaSms,
  FaClock,
  FaListAlt,
  FaPhone,
  FaClone,
  FaBan,
  FaCalendarCheck,
  FaStar,
  FaFilter,
  FaVideo,
  FaHome,
} from "react-icons/fa";

import { Auth } from "../../rbac/rbac";
import routes_constants from "../layout/routes";
import Dialog from "../../components/dialog/dialog";
import CustomButton from "../../components/custom-button";

import {
  formatActivityDate,
  formatDate,
  emailPreviewBody,
  truncate,
  addHttpToUrl,
  splitIntoFields,
  InactivityIcon,
  formatCallDurationsWithSeconds,
} from "../../utils/helpers";
import { LEAD, PROSPECT, HOMEOWNER, ROLE_OSC, REALTOR } from "../../utils/constants";

import { FETCH_CALL_RECORDING } from "../../graphql/queries";
import {
  DELETE_LEAD_FOLLOW_UP,
  MARK_LEAD_FOLLOW_UP_DONE,
  DELETE_LEAD_PHONE_CALL,
  DELETE_HOMEOWNER_APPOINTMENT,
  MARK_HOMEOWNER_APPOINTMENT_DONE,
  DELETE_HOMEOWNER_PHONE_CALL,
  DELETE_PROSPECT_PHONE_CALL,
  DELETE_PROSPECT_FOLLOW_UP,
  DELETE_PROSPECT_APPOINTMENT,
  MARK_PROSPECT_APPOINTMENT_DONE,
  MARK_PROSPECT_FOLLOW_UP_DONE,
  MARK_LEAD_APPOINTMENT_DONE,
  DELETE_LEAD_APPOINTMENT,
  DELETE_HOMEOWNER_FOLLOW_UP,
  MARK_HOMEOWNER_FOLLOW_UP_DONE,
  MARK_REALTOR_APPOINTMENT_DONE,
  MARK_REALTOR_FOLLOWUP_DONE
} from "../../graphql/mutations";

import massMailLogo from "../../images/icon-massEmailColored.svg";
import AppointmentDeleteDialogBox from "../../components/dialog/appointment-delete-dialog-box";
import { isNull } from "lodash";
import DELETE_REALTOR_APPOINTMENT from "../../graphql/mutations/activity/realtor/delete-appointment";
import DELETE_REALTOR_FOLLOW_UP from "../../graphql/mutations/activity/realtor/delete-follow-up";

/**
* This component is used to render the calendar activities popovers

* @param {object} props component props
* @param {object} props.item contains all the informations related to the activities
* @param {string} props.type type of the customer
* @param {function} props.markDoneLeadFollowUp
* @param {function} props.deleteLeadFollowUp
* @param {function} props.deleteLeadPhoneCall
* @param {function} props.markDoneProspectAppointment
* @param {function} props.deleteProspectAppointment
* @param {function} props.markDoneProspectFollowUp
* @param {function} props.deleteProspectFollowUp
* @param {function} props.deleteProspectPhoneCall
* @param {function} props.deleteHomeownerPhoneCall
* @param {function} props.handleDoneClick
* @param {function} props.markDoneRealtorAppointment
* @param {function} props.markDoneRealtorFollowup
* @param {function} props.onSubmit
*/
const Popover = (props) => {
  const { user } = useContext(Auth);
  const isOsc = user.role === ROLE_OSC || user.secondary_role === ROLE_OSC;

  const [state, setState] = useState({
    showDeleteConfirmation: false,
    id: [],
    typename: null,
    showMarkDoneConfirmation: false,
    showEmailPreview: false,
    recordingUrl: "",
    deleteNote: null
  });

  const handleInputChange = (value) => {
    setState(prevState => ({ ...prevState, deleteNote: value }));
  };

  const { refetch: getPhoneCallRecording } = useQuery(FETCH_CALL_RECORDING, { skip: true });

  /**
   * this function is used to show/hide delete confirmation dialog
   * @method
   * @param {object} item Contains all the information regarding the activity.
   */
  const handleDeleteClick = (item) => {
    setState({
      ...state,
      showDeleteConfirmation: !state.showDeleteConfirmation,
      id: [item.id],
      typename: item.__typename,
      recordingUrl: "",
    });
  };

  /**
   * this function is used to hide the dialogs
   * @method
   */
  const hideDialog = () => {
    setState({
      ...state,
      showDeleteConfirmation: false,
      showMarkDoneConfirmation: false,
      id: null,
      typename: null,
    });
  };

  /**
   * this function is used to fetch phone/voicemail recording from backend and open it in media player
   * @method
   */
  const handleFetchRecording = () => {
    getPhoneCallRecording({
      phone_call_id: props.item.event.phone_call_id,
    }).then((res) => {
      if (res.data.getPhoneCallRecording.code === 200) {
        setState({
          ...state,
          recordingUrl: res.data.getPhoneCallRecording.message,
        });
      }
    });
  };

  /**
   * this function is used to show/hide mark done confirmation dialog
   * @method
   * @param {object} item Contains all the information regarding the activity.
   */
  const handleMarkDoneClick = (item) => {
    setState({
      ...state,
      showMarkDoneConfirmation: !state.showMarkDoneConfirmation,
      id: item.id,
      typename: item.__typename,
    });
  };

  /**
   * this function is used to mark an activity done
   * @method
   */
  const handleMarkDoneConfirmation = () => {
    if (state.typename === "Appointment" || state.typename === "RealtorAppointment") {
      if (props.type === PROSPECT || props.item.event.prospect_id) {
        props
          .markDoneProspectAppointment({
            variables: {
              input: { appointment_ids: [state.id], done: true },
            },
          })
          .then((res) => {
            if (res.data.markDoneProspectAppointment.code === 200) {
              props.handleDoneClick();
              props.onSubmit(res.data.markDoneProspectAppointment.message);
            }
          });
      } else if (props.type === HOMEOWNER) {
        props
          .markDoneHomeownerAppointment({
            variables: {
              input: { appointment_ids: [state.id], done: true },
            },
          })
          .then((res) => {
            if (res.data.markDoneHomeownerAppointment.code === 200) {
              props.handleDoneClick();
              props.onSubmit(res.data.markDoneHomeownerAppointment.message);
            }
          });
      } else if (props.type === LEAD || props.item.event.lead_id) {
        props
          .markDoneLeadAppointment({
            variables: {
              input: { appointment_ids: [state.id], done: true },
            },
          })
          .then((res) => {
            if (res.data.markDoneLeadAppointment.code === 200) {
              props.handleDoneClick();
              props.onSubmit(res.data.markDoneLeadAppointment.message);
            }
          });
      } else if (props.type === REALTOR || props.item.event.realtor_id) {
        props
        .markDoneRealtorAppointment({
          variables: {
            input: { appointment_ids: [state.id], done: true },
          },
        })
        .then((res) => {
          if (res.data.markDoneRealtorAppointment.code === 200) {
            props.handleDoneClick();
            props.onSubmit(res.data.markDoneRealtorAppointment.message);
          }
        });
      }
    } else if (state.typename === "Prospect_Follow_Up") {
      props
        .markDoneProspectFollowUp({
          variables: { input: { follow_up_ids: [state.id], done: true } },
        })
        .then((res) => {
          if (res.data.markDoneProspectFollowUp.code === 200) {
            props.handleDoneClick();
            props.onSubmit(res.data.markDoneProspectFollowUp.message);
          }
        });
    } else if (state.typename === "Lead_Follow_Up") {
      props
        .markDoneLeadFollowUp({
          variables: { input: { follow_up_ids: [state.id], done: true } },
        })
        .then((res) => {
          if (res.data.markDoneLeadFollowUp.code === 200) {
            props.handleDoneClick();
            props.onSubmit(res.data.markDoneLeadFollowUp.message);
          }
        });
    } else if (state.typename === "Homeowner_Follow_Up") {
      props
        .markDoneHomeownerFollowUp({
          variables: { input: { follow_up_ids: [state.id], done: true } },
        })
        .then((res) => {
          if (res.data.markDoneHomeownerFollowUp.code === 200) {
            props.handleDoneClick();
            props.onSubmit(res.data.markDoneHomeownerFollowUp.message);
          }
        });
    } else if (state.typename === "Realtor_Follow_Up") {
      props
        .markDoneRealtorFollowup({
          variables: { input: { follow_up_ids: [state.id], done: true } },
        })
        .then((res) => {
          if (res.data.markDoneRealtorFollowup.code === 200) {
            props.handleDoneClick();
            props.onSubmit(res.data.markDoneRealtorFollowup.message);
          }
        });
    }
  };

  /**
   * this function is used when delete confirmation has been made and deletes the activity
   * @method
   */
  const handleDeleteConfirmation = () => {
    if (state.typename === "Appointment" || state.typename === "RealtorAppointment") {
      if (props.type === PROSPECT) {
        props
          .deleteProspectAppointment({
            variables: { input: { appointment_ids: state.id, note: state.deleteNote } },
          })
          .then((res) => {
            if (res.data.deleteProspectAppointment.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteProspectAppointment.message);
            }
          });
      } else if (props.type === HOMEOWNER) {
        props
          .deleteHomeownerAppointment({
            variables: { input: { appointment_ids: state.id, note: state.deleteNote } },
          })
          .then((res) => {
            if (res.data.deleteHomeownerAppointment.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteHomeownerAppointment.message);
            }
          });
        } else if (props.type === REALTOR) {
          props
            .deleteRealtorAppointment({
              variables: { input: { appointment_ids: state.id, note: state.deleteNote } },
            })
            .then((res) => {
              if (res.data.deleteRealtorAppointment.code === 200) {
                setState({
                  ...state,
                  showDeleteConfirmation: !state.showDeleteConfirmation,
                });
                props.onSubmit(res.data.deleteRealtorAppointment.message);
              }
            });
      } else if (props.type === LEAD) {
        props
          .deleteLeadAppointment({
            variables: { input: { appointment_ids: state.id, note: state.deleteNote } },
          })
          .then((res) => {
            if (res.data.deleteLeadAppointment.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteLeadAppointment.message);
            }
          });
      }
    } else if (state.typename === "PhoneCall" || state.typename === "Text") {
      if (props.type === PROSPECT) {
        props
          .deleteProspectPhoneCall({
            variables: {
              input: props.item.event.phone_call_id,
            },
          })
          .then((res) => {
            if (res.data.deleteProspectPhoneCall.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteProspectPhoneCall.message);
            }
          });
      } else if (props.type === LEAD) {
        props
          .deleteLeadPhoneCall({
            variables: {
              input: props.item.event.phone_call_id,
            },
          })
          .then((res) => {
            if (res.data.deleteLeadPhoneCall.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteLeadPhoneCall.message);
            }
          });
      } 
      else if (props.type === HOMEOWNER) {
        props
          .deleteHomeownerPhoneCall({
            variables: {
              input: props.item.event.phone_call_id,
            },
          })
          .then((res) => {
            if (res.data.deleteHomeownerPhoneCall.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteHomeownerPhoneCall.message);
            }
          });
      }
    } else if (state.typename === "Prospect_Follow_Up") {
      props
        .deleteProspectFollowUp({
          variables: { input: { follow_up_ids: state.id } },
        })
        .then((res) => {
          if (res.data.deleteProspectFollowUp.code === 200) {
            setState({
              ...state,
              showDeleteConfirmation: !state.showDeleteConfirmation,
            });
            props.onSubmit(res.data.deleteProspectFollowUp.message);
          }
        });
    } else if (state.typename === "Lead_Follow_Up") {
      props
        .deleteLeadFollowUp({
          variables: { input: { follow_up_ids: state.id } },
        })
        .then((res) => {
          if (res.data.deleteLeadFollowUp.code === 200) {
            setState({
              ...state,
              showDeleteConfirmation: !state.showDeleteConfirmation,
            });
            props.onSubmit(res.data.deleteLeadFollowUp.message);
          }
        });
      } else if (state.typename === "Realtor_Follow_Up") {
        props
          .deleteRealtorFollowUp({
            variables: { input: { follow_up_ids: state.id } },
          })
          .then((res) => {
            if (res.data.deleteRealtorFollowUp.code === 200) {
              setState({
                ...state,
                showDeleteConfirmation: !state.showDeleteConfirmation,
              });
              props.onSubmit(res.data.deleteRealtorFollowUp.message);
            }
          });  
    } else if (state.typename === "Homeowner_Follow_Up") {
      props
        .deleteHomeownerFollowUp({
          variables: { input: { follow_up_ids: state.id } },
        })
        .then((res) => {
          if (res.data.deleteHomeownerFollowUp.code === 200) {
            setState({
              ...state,
              showDeleteConfirmation: !state.showDeleteConfirmation,
            });
            props.onSubmit(res.data.deleteHomeownerFollowUp.message);
          }
        });
    }
  };

  useEffect(() => {
    if (
      props.item.event.__typename === "PhoneCall" &&
      props.item.event.recording
    ) {
      handleFetchRecording();
    }
  }, []);

  let date;
  let endDate;
  let newDate;
  let csmNames;

  if (props.item.event.__typename === "Appointment" 
    || props.item.event.__typename === "RealtorAppointment") {
    date = new Date(props.item.event.start_datetime);
    endDate = new Date(props.item.event.end_datetime);
    newDate = formatActivityDate(date) + " - " + formatActivityDate(endDate);
  } else if (
    props.item.event.__typename === "PhoneCall" ||
    props.item.event.__typename === "Text"
  ) {
    date = new Date(props.item.event.call_datetime);
    newDate = formatActivityDate(date);
  } else if (props.item.event.__typename === "Email") {
    date = new Date(props.item.event.date_time);
    newDate = formatActivityDate(date);
    csmNames = props.item.event.csms.map(
      (csm) => csm.first_name + " " + csm.last_name
    );
  } else if (props.item.event.__typename === "MailchimpEmailType") {
    date = new Date(props.item.event.datetime);
    newDate = formatActivityDate(date);
  } else if (props.item.event.__typename.includes("Follow_Up")) {
    date = new Date(props.item.event.follow_up_datetime);
    newDate = formatDate(date);
  } else if (props.item.event.__typename === "Prospect_Walk_In") {
    date = new Date(props.item.event.created_at);
    newDate = formatActivityDate(date);
  }
 
  return (
    <div className="popover-group-events-calendar">
  <Dialog
  show={state.showDeleteConfirmation}
  onHide={hideDialog}
  title="Delete Confirmation"
  body={
    state.typename === "Appointment" || state.typename === "RealtorAppointment" ? (
      <AppointmentDeleteDialogBox handleInputChange={handleInputChange} />
    ) : (
      <p>Are you sure you want to delete?</p>
    )
  }
  click={handleDeleteConfirmation}
  disabled={
    (state.typename === "Appointment" || state.typename === "RealtorAppointment") &&
    !(state?.deleteNote)
  }
  clickname="YES"
  closename="NO"
/>

      <Dialog
        show={state.showMarkDoneConfirmation}
        onHide={hideDialog}
        title="Mark Done Confirmation"
        body="Are you sure you want to mark the record as Done?"
        click={handleMarkDoneConfirmation}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        backdrop={true}
        size="email-event"
        show={state.showEmailPreview}
        onHide={() => {
          setState({ ...state, showEmailPreview: false });
        }}
        title="Email"
        noHeader={true}
        body={emailPreviewBody(props.item.event, newDate, csmNames, () =>
          setState({ ...state, showEmailPreview: false })
        )}
      />
      <div className="d-flex flex-column popover_Title">
        <div className="d-flex justify-content-between">
          <div>
            {props.item.event.__typename === "Appointment" 
            || props.item.event.__typename === "RealtorAppointment" ? (
              <div
                className="popover_Title_small"
                style={{ color: `${props.item.event.__typename === "RealtorAppointment" 
                  ? "rgba(212, 186, 42)" 
                  : "rgb(146, 175, 0)" }` 
                }}
              >
                <FaCalendarCheck /> {props.item.event.__typename === "RealtorAppointment" 
                ? "Realtor Appointment"
                : props.item.event.__typename}
              </div>
            ) : props.item.event.__typename === "PhoneCall" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(80, 227, 194, 1)" }}
              >
                <FaPhone size={12} /> {props.item.event.__typename}
              </div>
            ) : props.item.event.__typename === "Text" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(87, 71, 49, 1)" }}
              >
                <FaSms /> Text
              </div>
            ) : props.item.event.__typename === "Email" &&
              props.item.event.isIndividual ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(255, 122, 0, 1)" }}
              >
                <FaEnvelope /> {props.item.event.__typename}
              </div>
            ) : props.item.event.__typename === "Email" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(148, 0, 255, 1)" }}
              >
                <i>
                  <img
                    src={massMailLogo}
                    style={{ fill: "red" }}
                    className="mr-1"
                    alt=""
                  />
                </i>{" "}
                Mass Email
              </div>
            ) : props.item.event.__typename === "MailchimpEmailType" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(208, 2, 27, 1)" }}
              >
                <FaEnvelope /> EBomb
              </div>
            ) : props.item.event.__typename === "CustomerFormSubmission" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(255, 94, 94, 1)" }}
              >
                <FaListAlt /> Web Form
              </div>
            ) : props.item.event.__typename === "Prospect_Walk_In" ? (
              <div
                className="popover_Title_small"
                style={{ color: "rgb(168, 66, 100, 1)" }}
              >
                <FaHome /> Walk-In
              </div>
            ) : (
              <div
                className="popover_Title_small"
                style={{ color: `${props.item.event.__typename === "Realtor_Follow_Up" 
                  ? "rgba(212, 42, 150)" 
                  : "rgb(76, 126, 249)" }` 
                }}
              >
                <FaCalendarDay /> 
                {props.item.event.__typename === "Realtor_Follow_Up" ? "Realtor Follow-Up" : "Follow-Up"}
              </div>
            )}
          </div>
          

          {props.item.event.__typename === "Appointment" ||
            props.item.event.__typename === "RealtorAppointment" ||
            props.item.event.__typename === "Homeowner_Follow_Up" ||
            props.item.event.__typename === "Prospect_Follow_Up" ||
            props.item.event.__typename === "Lead_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ||
            props.item.event.__typename === "PhoneCall" ||
            props.item.event.__typename === "Text" ? (
            <div className="d-flex flex-row">
              {props.id === "dashboard-calendar" && (
                <NavLink
                  to={{
                    pathname: props.item.event.prospect_id
                    ? `${routes_constants.PROSPECT_DETAIL}/${props.item.event.prospect_id}`
                    : props.item.event.lead_id
                    ? `${routes_constants.LEAD_DETAIL}/${props.item.event.lead_id}`
                    : props.item.event.realtor_id
                    ? `${routes_constants.REALTOR_DETAIL}/${props.item.event.realtor_id}`
                    : null,
                  id:
                    props.item.event.prospect_id ||
                    props.item.event.lead_id ||
                    props.item.event.realtor_id,
                  }}
                  className="text-muted"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Show Details</Tooltip>}
                  >
                    <CustomButton
                      size="sm"
                      color="dark"
                      btnIcon={
                        props.item.event.prospect_id ? (
                          <FaStar size={14} />
                        ) : (
                          <FaFilter size={14} />
                        )
                      }
                      className="popover-icon-btn btn-icon"
                    />
                  </OverlayTrigger>
                </NavLink>
              )}
              {(props.item.event.__typename === "Appointment" ||
              props.item.event.__typename === "RealtorAppointment" ||
                props.item.event.__typename === "Homeowner_Follow_Up" ||
                props.item.event.__typename === "Prospect_Follow_Up" ||
                props.item.event.__typename === "Lead_Follow_Up" ||
                props.item.event.__typename === "Realtor_Follow_Up") &&
                !props.item.event.done && (
                  <CustomButton
                    size="sm"
                    color="dark"
                    btnIcon={<FaCheck size={14} />}
                    onClick={() => handleMarkDoneClick(props.item.event)}
                    className="popover-icon-btn btn-icon"
                  />
                )}{" "}
              {props.id !== "dashboard-calendar" &&
                props.type !== "webform" && (
                  <>
                    {(isOsc || 
                      !(
                        props.type === LEAD && 
                        (props.item.event.__typename === "Appointment" ||
                        props.item.event.__typename === "RealtorAppointment")
                      )) && (
                        <CustomButton
                          size="sm"
                          color="dark"
                          btnIcon={<FaPen size={14} />}
                          onClick={() =>
                            props.editComp({
                              ...props.item.event,
                              csm:
                                props.item.event.csm &&
                                  !props.item.event.csm.is_active
                                  ? null
                                  : props.item.event.csm,
                              inActiveCsm:
                                props.item.event.csm &&
                                  props.item.event.csm.is_active
                                  ? null
                                  : props.item.event.csm,
                            })
                          }
                          className="popover-icon-btn btn-icon"
                        />
                      )}
                    <CustomButton
                      size="sm"
                      color="dark"
                      btnIcon={<FaTrash size={14} />}
                      onClick={() => handleDeleteClick(props.item.event)}
                      className="popover-icon-btn btn-icon"
                    />
                  </>
                )}
            </div>
          ) : props.item.event.__typename === "CustomerFormSubmission" ? (
            <div className="webform-response-medium">
              <ButtonToolbar
                className="d-flex"
                style={{ alignItems: "center" }}
              >
                <CustomButton
                  color="dark"
                  disabled={true}
                  btnIcon={
                    <FaSms
                      size={14}
                      style={{
                        color: props.item.event.response_sms && "#80b602",
                      }}
                    />
                  }
                  className="icon-btn btn-icon"
                  style={{ background: "none", pointerEvents: "none" }}
                />
                <CustomButton
                  color="dark"
                  disabled={true}
                  btnIcon={
                    <FaPhone
                      size={14}
                      style={{
                        color: props.item.event.response_phone && "#80b602",
                      }}
                    />
                  }
                  className="icon-btn btn-icon b-none"
                  style={{ background: "none", pointerEvents: "none" }}
                />
                <CustomButton
                  color="dark"
                  disabled={true}
                  btnIcon={
                    <FaEnvelope
                      size={14}
                      style={{
                        color: props.item.event.response_email && "#80b602",
                      }}
                    />
                  }
                  className="icon-btn btn-icon"
                  style={{ background: "none", pointerEvents: "none" }}
                />
              </ButtonToolbar>
            </div>
          ) : props.item.event.__typename === "Email" ? (
            <CustomButton
              color="dark"
              onClick={() => {
                setState({ ...state, showEmailPreview: true });
              }}
              btnIcon={
                <FaClone
                  size={14}
                  style={{
                    color: "#80b602",
                  }}
                />
              }
              className="icon-btn btn-icon b-none"
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {props.item.event.__typename === "CustomerFormSubmission" ? (
        <ul className="list-unstyled event-details-list">
          <li>
            <label>Received at:</label>
            <span>{formatActivityDate(props.item.event.created_at)}</span>
          </li>
          <li>
            <label>Form Type:</label>
            <span>{props.item.event.form_type || "-"}</span>
          </li>
          <li>
            <label>Division:</label>
            <span>{props.item.event.division?.name || "-"}</span>
          </li>
          <li>
            <label>Community:</label>
            <span>
              {props.item.event.community?.is_active === false && (
                <FaBan className="td-warning" size={10} />
              )}
              {props.item.event.community?.name || "-"}
            </span>
          </li>
          <li>
            <label>URL:</label>
            {props.item.event.url ? (
              <a
                target="_blank"
                href={addHttpToUrl(props.item.event.url)}
                rel="noopener noreferrer"
              >
                {props.item.event.url}
              </a>
            ) : (
              <span>{"-"}</span>
            )}
          </li>
          <li>
            <label>Details:</label>
            <span>{splitIntoFields(props.item.event.interested_in)}</span>
          </li>
          <li>
            <label>Other Data:</label>
            <span>{splitIntoFields(props.item.event.other_data)}</span>
          </li>
          <li>
            <label>Responded by:</label>
            <span>
              {props.item.event.responder
                ? `${props.item.event.responder.first_name} ${props.item.event.responder.last_name}`
                : "-"}
              {props.item.event.responded_at &&
                ` at ${formatActivityDate(props.item.event.responded_at)}`}
            </span>
          </li>
        </ul>
      ) : props.item.event.__typename === "Email" ? (
        <ul className="list-unstyled event-details-list">
          <li>
            <label>Subject</label>
            <span>{props.item.event.subject}</span>
          </li>
          <li>
            <label>Date</label>
            <span>
              <FaCalendarDay /> {newDate}
            </span>
          </li>
          <li>
            <label>Type</label>
            <span style={{ textTransform: "capitalize" }}>
              {props.item.event.email_type}
            </span>
          </li>
          <li>
            <label>BY:</label>
            <span>{csmNames.join(", ")}</span>
          </li>
          <li style={{ flexDirection: "column" }}>
            <label>Message</label>
            <span className="email-message">
              {truncate(props.item.event.body_preview, 254)}
            </span>
          </li>
          {props.item.event?.hasAttachments && (
            <li>
              <FaPaperclip /> Attachments
            </li>
          )}
        </ul>
      ) : props.item.event.__typename === "MailchimpEmailType" ? (
        <ul className="list-unstyled event-details-list">
          <li>
            <label>Subject</label>
            <span>{props.item.event.subject}</span>
          </li>
          <li>
            <label>Content URL</label>
            <a
              href={props.item.event.content_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.item.event.content_url}
            </a>
          </li>
          <li>
            <label>Address</label>
            <span>{props.item.event.email_addresses.toString()}</span>
          </li>
          <li>
            <label>From</label>
            <span>{props.item.event.email_from}</span>
          </li>
          <label>Date</label>
          <span>
            <FaCalendarDay /> {newDate}
          </span>
        </ul>
      ) : (
        <ul className="list-unstyled event-details-list">
          {props.item.event.__typename === "PhoneCall" ||
            props.item.event.__typename === "Text" ? (
            <>
              <li>
                <label>
                  {props.item.event.call_type?.id < 4
                    ? "Call Type"
                    : "Text Type"}
                </label>
                <span>{props.item.event.call_type?.name || "NA"}</span>
              </li>
              <li>
                <label>Sent By</label>
                <span>
                  {props.item.event.creator &&
                    props.item.event.creator.first_name +
                    " " +
                    props.item.event.creator.last_name}
                </span>
              </li>
            </>
          ) : (
            <>
              {props.item?.event?.isDashBoardCalender ? (
                <li>
                  <label>Name</label>
                  <span>
                    {props.item.event.first_name_1 || ""}{" "}
                    {props.item.event.last_name_1 || ""}
                  </span>
                </li>
              ) : (
                ""
              )}
              <li>
                <label>Title</label>
                <span>{props.item.event.title}</span>
              </li>
            </>
          )}
          {(props.item.event.__typename === "Appointment" 
          || props.item.event.__typename === "RealtorAppointment") && (
            <>
              <li>
                <label>Type</label>
                <span>
                  {props.item.event.Appointment_Type &&
                    props.item.event.Appointment_Type.name}
                </span>
                {props.item.event.zoom_meeting_link && (
                  <span>
                    <FaVideo
                      size={14}
                      className="video-icon"
                      onClick={() => {
                        window.open(props.item.event.zoom_meeting_link);
                      }}
                    />
                  </span>
                )}
              </li>
            </>
          )}
          {props.item.event.__typename === "PhoneCall" ||
            props.item.event.__typename === "Text" ||
            props.item.event.__typename === "Homeowner_Follow_Up" ||
            props.item.event.__typename === "Prospect_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ||
            props.item.event.__typename === "Lead_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ? (
            <li>
              <label>Details</label>
              <span>
                {props.item.event.attachments?.length ? props.item.event.attachments.map((attachment) => (
                  <FaPaperclip size={11} sx={{ ml: 5 }} />
                )) : null}
                {props.item.event.call_details || props.item.event.details}
              </span>
            </li>
          ) : null}
          {props.item.event.__typename === "PhoneCall" ? (
            <li>
              <label>Duration</label>
              <span>
                {props.item.event.call_duration > 0 ? (
                  <>
                    <FaClock />{" "}
                    {formatCallDurationsWithSeconds(
                      props.item.event.call_duration
                    )}
                  </>
                ) : (
                  "No Answer"
                )}
              </span>
            </li>
          ) : null}
          {props.item.event.__typename === "Appointment" ||
          props.item.event.__typename === "RealtorAppointment" ||
            props.item.event.__typename === "Homeowner_Follow_Up" ||
            props.item.event.__typename === "Prospect_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ||
            props.item.event.__typename === "Lead_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ? (
            <li>
              <div style={{ fontSize: "12px" }}>
                <div className="d-flex flex-row">
                  <label>ASSIGNED TO</label>{" "}
                  {InactivityIcon(
                    props.item.event.csm,
                    props.item.event.csm &&
                    `${props.item.event.csm.first_name} ${props.item.event.csm.last_name}`
                  )}
                </div>
                {props.item.event.__typename === "Appointment" ||
                props.item.event.__typename === "RealtorAppointment" ||
                  props.item.event.__typename === "PhoneCall" ||
                  props.item.event.__typename === "Text" ||
                  props.item.event.__typename === "Homeowner_Follow_Up" ||
                  props.item.event.__typename === "Prospect_Follow_Up" ||
                  props.item.event.__typename === "Realtor_Follow_Up" ||
                  props.item.event.__typename === "Lead_Follow_Up" ||
                  props.item.event.__typename === "Realtor_Follow_Up" ? (
                  <>
                    <label>COMMUNITY</label>
                    <span>{`${props.item?.event?.community?.name || props.data?.community?.name || ""}`}</span>
                  </>
                ) : null}
              </div>
            </li>
          )
            : null
          }
          <li>
            <label>Date</label>
            <span>
              <FaCalendarDay /> {newDate}
            </span>
          </li>
          {props.item.event.__typename === "Appointment" ||
          props.item.event.__typename === "RealtorAppointment" ||
            props.item.event.__typename === "Homeowner_Follow_Up" ||
            props.item.event.__typename === "Prospect_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ||
            props.item.event.__typename === "Lead_Follow_Up" ||
            props.item.event.__typename === "Realtor_Follow_Up" ? (
            <>
              <li>
                <label>Set By</label>
                <span>
                  {props.item.event.creator &&
                    `${props.item.event.creator.first_name} ${props.item.event.creator.last_name} 
                    at ${formatActivityDate(props.item.event.created_at)}`}
                </span>
              </li>
            </>
          ) : null}
          {props.item.event.__typename === "PhoneCall" &&
            props.item.event.recording && (
              <li>
                <label>Recording</label>
                <audio controls src={state.recordingUrl}>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </li>
            )}
        </ul>
      )}
    </div>
  );
};

export default compose(
  graphql(DELETE_PROSPECT_FOLLOW_UP, { name: "deleteProspectFollowUp" }),
  graphql(MARK_PROSPECT_FOLLOW_UP_DONE, { name: "markDoneProspectFollowUp" }),
  graphql(DELETE_PROSPECT_APPOINTMENT, { name: "deleteProspectAppointment" }),
  graphql(MARK_PROSPECT_APPOINTMENT_DONE, { name: "markDoneProspectAppointment" }),
  graphql(DELETE_PROSPECT_PHONE_CALL, { name: "deleteProspectPhoneCall" }),

  graphql(MARK_LEAD_APPOINTMENT_DONE, { name: "markDoneLeadAppointment" }),
  graphql(DELETE_LEAD_APPOINTMENT, { name: "deleteLeadAppointment" }),
  graphql(MARK_LEAD_FOLLOW_UP_DONE, { name: "markDoneLeadFollowUp" }),
  graphql(DELETE_LEAD_FOLLOW_UP, { name: "deleteLeadFollowUp" }),
  graphql(DELETE_LEAD_PHONE_CALL, { name: "deleteLeadPhoneCall" }),

  graphql(DELETE_HOMEOWNER_FOLLOW_UP, { name: "deleteHomeownerFollowUp" }),
  graphql(MARK_HOMEOWNER_FOLLOW_UP_DONE, { name: "markDoneHomeownerFollowUp" }),
  graphql(DELETE_HOMEOWNER_APPOINTMENT, { name: "deleteHomeownerAppointment" }),
  graphql(MARK_HOMEOWNER_APPOINTMENT_DONE, { name: "markDoneHomeownerAppointment" }),
  graphql(DELETE_HOMEOWNER_PHONE_CALL, { name: "deleteHomeownerPhoneCall" }),
  
  graphql(DELETE_REALTOR_APPOINTMENT, { name: "deleteRealtorAppointment" }),
  graphql(DELETE_REALTOR_FOLLOW_UP, { name: "deleteRealtorFollowUp" }),
  graphql(MARK_REALTOR_APPOINTMENT_DONE, {name: "markDoneRealtorAppointment"}),
  graphql(MARK_REALTOR_FOLLOWUP_DONE, {name: "markDoneRealtorFollowup"})

)(Popover);
