import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { FaUserFriends } from "react-icons/fa";
import { ButtonToolbar } from "react-bootstrap";
import { Form, Field, withFormik } from "formik";
import CustomButton from "../../components/custom-button";
import TextField from "../../components/fields/text-field";
import SelectField from "../../components/fields/select-field";
import ErrorFocus from "../../components/error-focus/error-focus";
import CheckboxField from "../../components/fields/checkbox-field";
import AddEmailField from "../../components/fields/add-email-field";
import MultiSelectField from "../../components/fields/multi-select-field";
import { AgenciesCreatableSelect } from "../../components/agencies-creatable-select";
import { DetailsSectionComponent } from './details/registration-detail'
import { GET_COMMUNITIES } from '../../graphql/queries'
import { initialValues, realtorSchema } from "./realtor-schema";
import { phoneDisplay, redirectToDashboard, filterCommunitiesByDivisions } from "../../utils/helpers";
import "../../views/prospect/dashboard/prospect-dashboard.css";
import Dialog from "../../components/dialog/dialog";

/**
 * This component is responsible to render the create, edit and merge form of the realtor. It has mainly textfield, and also checkbox  
 * and add email fields, with 3 required fields like first name primary email and division. Communities are only visible and can be 
 * selected if the Realtor is a listung agent of SB. On form submit form-data is formatted back to the values expected by backend
 * and edit-form mutation is called.
 * 
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Function} props.resetForm Function to reset the form on close or on submit
 * @param {Object} props.realtor contains information on realtor to be edit
 * @param {Object} props.registration contains information on realtor registration
 * @param {Object} props.onMerge contains information on realtors being merged
 */
const RealtorForm = props => {
  const { status, realtor, resetForm, registration, onMerge, values } = props;
  const { data } = useQuery(GET_COMMUNITIES, { variables: { division_id: props.values.division_id } });
  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({});
  const onCancel = () => {
    resetForm();
    props.close();
  };

  const handleMergeBody = () => {
    return (
      <>
        {status.showAddRealtorErrorMessage}
        <p>Do you want to merge?</p>
      </>
    );
  };

  const handleCancelMerge = () => {
    props.setStatus({
      showAddRealtorError: false,
      showAddRealtorErrorMessage: null,
    });
  };

  const handleMerge = async () => {
    const realtor = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      primary_email: values?.primary_email,
      agency_name: values?.agency_name === "" ? null : values?.agency_name ,
      cell_phone: values?.cell_phone,
      is_listing_agent_of_sb: values?.is_listing_agent_of_sb,
      Communities: values?.Communities,
      division_id: values?.division_id,
      Realtor_Other_Emails: values?.Realtor_Other_Emails ? values.Realtor_Other_Emails.map(email => ({ email_address: email })) : [],
      webform_id: props?.webformId
    }

    const {
      data: { mergeRealtors: { code, message } }
    } = await props.mergeRealtors({
      variables:
        { ids: null, realtor_data: realtor }
    })

    props.setNotificationProps({ variables: { open: true, message } });
    if (code === 200) {
      props.close()
      props.onSubmit();
    }
    props.setSubmitting(false);
  }

  return (
    <Form className="form-horizontal">
      <Dialog
            show={status?.showAddRealtorError}
            size="md"
            title="Realtor Duplication"
            onHide={handleCancelMerge}
            body={status ? handleMergeBody() : null}
            click={handleMerge}
            clickname="YES"
            closename="NO"
          />
      <div className="form_TitleContainer">
        <div className={registration ? "detail-partition" : ""}>
          <div
            className={`d-flex justify-content-between align-items-center 
              ${onMerge ? `form-merge` : ""}`}
            style={{ margin: "0 1.1rem" }}
          >
            <div>
              {!onMerge && <div className="form_Title">Realtor</div>}
              <div className="form_TitleBig mt-1">
                <h2>
                  {onMerge && <FaUserFriends />}
                  {onMerge
                    ? "Merge Realtor"
                    : !realtor
                      ? "Create New"
                      : `${realtor.first_name} ${realtor.last_name}`}
                </h2>
              </div>
            </div>
            <div className="d-flex pr-3">
              {!onMerge ?
                <ButtonToolbar>
                  <CustomButton
                    color="black"
                    className="form_CancelButton"
                    onClick={onCancel}
                    btnValue="CANCEL"
                  />
                  <CustomButton
                    className="form_SubmitButton"
                    type="submit"
                    disabled={props.isSubmitting}
                    btnValue={realtor?.id ? "UPDATE" : "CREATE"}
                  />
                </ButtonToolbar>
                :
                <CustomButton
                  size="lg"
                  className="float-right"
                  btnValue="Merge"
                  type="submit"
                />
              }
            </div>
          </div>
        </div>
        {registration &&
          <div className="detail-background">
            <div style={{ margin: "0px 1.1rem" }}>
              <DetailsSectionComponent registration={registration} />
            </div>
          </div>
        }
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name"
              id="first_name"
              type="text"
              required
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name"
              id="last_name"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email"
                id="primary_email"
                type="email"
                disable={registration}
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1
                  }
                ]}
                required
                component={TextField}
              />
            </div>
            <div className="col-md-12 padding-less">
              <Field
                label="Cell Phone"
                name="formatted_cell_phone"
                id="formatted_cell_phone"
                isCellPhone={true}
                type="text"
                component={TextField}
              />
            </div>
            <div className="col-md-12 padding-less">
              <Field
                label="Division"
                name="division_id"
                id="division_id"
                fieldsToClear={[
                  { fieldName: "Communities", value: [] }
                ]}
                required
                component={SelectField}
              >
                <option value="">Select...</option>
                {props.getAllDivisions?.getAllDivisions?.map((division, index) => (
                  <option key={index} value={division.id}>
                    {division.name}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="Realtor_Other_Emails"
              id="Realtor_Other_Emails"
              type="text"
              initValue={realtor?.Realtor_Other_Emails?.length
                ? [...realtor.Realtor_Other_Emails.map(e => e.email_address)]
                : []
              }
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1
                }
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              id="is_listing_agent_of_sb"
              label="Is listing agent of SB?"
              name="is_listing_agent_of_sb"
              component={CheckboxField}
            />
          </div>
        </div>
        {props.values.is_listing_agent_of_sb ? (
          <div className="form-row">
            <div className="col-md-6">
              <Field
                label="Communities"
                name="Communities"
                id="Communities"
                menuPosition='top'
                component={MultiSelectField}
                children={filterCommunitiesByDivisions(data?.getAllCommunities, [values.division_id])}
                defaultValue={props.values.Communities}
              />
            </div>
          </div>
        ) : null}
        <hr className="light" />
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Agency Name"
              name="agency_name"
              id="agency_name"
              type="text"
              required
              fieldValueConfig={[
                { field: "agency_name", property: "name" },
                { field: "agency_address", property: "address" },
                { field: "city", property: "city" },
                { field: "state", property: "state.name" },
                { field: "zip", property: "zip" },
              ]}
              component={AgenciesCreatableSelect}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Street Address"
              name="agency_address"
              id="agency_address"
              type="text"
              disabled
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              disabled
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="State"
              name="state"
              id="state"
              type="text"
              disabled
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              disabled
              component={TextField}
            />
          </div>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

const mapPropsToValues = props => {
  const { realtor } = props;
  if (!realtor) return { ...initialValues }

  const { Agency, primary_email_status, primary_email, registration_count, sales_count, division, ...rest } = realtor

  return {
    ...rest,
    division_id: division?.id || null,
    formatted_cell_phone: phoneDisplay(rest.cell_phone),
    primary_email: primary_email,
    Realtor_Other_Emails: rest?.Realtor_Other_Emails?.map(r => r.email_address),
    Communities: rest.Communities?.map(item => item.id),
    agency_name: Agency?.name,
    agency_address: Agency?.address,
    city: Agency?.city,
    state: Agency?.state?.name,
    zip: Agency?.zip,
  }
};

const mutate = async (realtor) => {
  const { id, __typename, ...input } = realtor;
  const mutation = realtor.id ? realtor.updateRealtor : realtor.addRealtor;
  const prop = realtor.id ? "updateRealtor" : "addRealtor";

  const {
    data: {
      [prop]: { code, message }
    }
  } = await mutation({
    variables: {
      id,
      input
    }
  });

  return { code, message };
};

export const RegisterUpdate = withFormik({
  displayName: "RealtorFormikForm",
  enableReinitialize: true,
  validationSchema: realtorSchema,
  mapPropsToValues,
  handleSubmit: async (
    values,
    {
      props: {
        onSubmit,
        setNotificationProps,
        addRealtor,
        updateRealtor,
        webformId,
        customerId,
      },
      setSubmitting,
      resetForm,
      setStatus
    }
  ) => {
    const { 
      appointments,
      follow_ups,
      agency_address, 
      city, 
      state, 
      zip, 
      hidden_Realtor_Other_Emails, 
      formatted_cell_phone, 
      ...rest 
    } = values
    const submitValues = Object.assign({}, rest);

    if (!submitValues.is_listing_agent_of_sb) submitValues.Communities = [];
    submitValues.agency_name = submitValues.agency_name === "" ? null : submitValues.agency_name

    const { code, message } = await mutate(
      {
        addRealtor,
        updateRealtor,
        ...submitValues,
        cell_phone: submitValues.cell_phone || null,
        Realtor_Other_Emails: submitValues.Realtor_Other_Emails?.map(email => ({
          email_address: email
        })),
        customer_id: customerId,
        webform_id: webformId
      }
    );

    if(code === 410) {
      setStatus({
        showAddRealtorError: true,
        showAddRealtorErrorMessage: message,
    })} else 
      setNotificationProps({ variables: { open: true, message } });  

    if (code === 200) {
      resetForm();
      onSubmit();
    }
    setSubmitting(false);
  }
})(RealtorForm)

export const MergeRealtor = withFormik({
  displayName: "RealtorFormikFormMerge",
  enableReinitialize: true,
  validationSchema: realtorSchema,
  mapPropsToValues,
  handleSubmit: async (
    values,
    { props: { closeDialog, realtors_ids, mergeRealtors, onSubmit, setNotificationProps } }
  ) => {
    if (!values.is_listing_agent_of_sb) values.Communities = [];

    const realtor = {
      first_name: values.first_name,
      last_name: values.last_name,
      primary_email: values.primary_email,
      agency_name: values.agency_name === "" ? null : values.agency_name,
      cell_phone: values.cell_phone,
      is_listing_agent_of_sb: values.is_listing_agent_of_sb,
      Communities: values.Communities,
      division_id: values.division_id,
      Realtor_Other_Emails: values.Realtor_Other_Emails?.map(email => ({ email_address: email })),
    }

    const {
      data: { mergeRealtors: { code, message } }
    } = await mergeRealtors({
      variables:
        { ids: realtors_ids, realtor_data: realtor }
    })

    setNotificationProps({ variables: { open: true, message } });
    if (code === 200) {
      closeDialog()
      onSubmit();
      redirectToDashboard(window.location)
    }
  }
})(RealtorForm)
