import React from "react";

/**
 * This component is used to render the body of the appointment delete dialog box.
 * It shows a confirmation message along with an input to leave reason to delete.
 * @param {Object} props 
 * @param {Function} handleInputChange it is used to set note value
 */
const AppointmentDeleteDialogBox = ({ handleInputChange }) => {
  return (
    <>
      <p className="mb-0"> Are you sure you want to delete? </p>
      <div id="delete-form-group" className="form-group">
        <hr className="light" />
        <label htmlFor="delete-input" className="control-label">
          Reason for Cancel
        </label>
        <textarea
          id="delete-input"
          type="text"
          name="note"
          className="form-control"
          rows={3}
          onChange={event => handleInputChange(event.currentTarget.value)}
        />
      </div>
    </>
  );
};

export default AppointmentDeleteDialogBox;
