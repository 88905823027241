import gql from 'graphql-tag';

const DELETE_REALTOR_APPOINTMENT = gql` 
  mutation deleteRealtorAppointment($input: RealtorAppointmentDeleteInput!) {
    deleteRealtorAppointment(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_REALTOR_APPOINTMENT;