import { graphql, compose } from "react-apollo";
import UpcomingTasksTable from "./upcoming-tasks-table";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_TABLE_PARAMS,
  GET_SELECTED_CSM,
  GET_DEFAULT_VIEW,
  GET_CALENDAR_DATE_RANGE,
  GET_SHOW_MY_APPOINTMENTS_ONLY
} from "../../cacheql/queries";
import {
  SET_TABLE_PARAMS,
  SELECT_DEFAULT_VIEW,
  SET_NOTIFICATION_PROPS,
  SET_CALENDAR_DATE_RANGE,
  SET_SHOW_MY_APPOINTMENTS_ONLY
} from "../../cacheql/mutations";
import {
  MARK_PROSPECT_FOLLOW_UP_DONE,
  MARK_PROSPECT_APPOINTMENT_DONE,
  MARK_LEAD_APPOINTMENT_DONE,
  MARK_LEAD_FOLLOW_UP_DONE,
  MARK_REALTOR_APPOINTMENT_DONE,
  MARK_REALTOR_FOLLOWUP_DONE,
  TASK_CHANGE_CSM
} from "../../graphql/mutations";
import {
  GET_ALLRANKINGS,
  GET_COMMUNITIES,
  GET_CSM,
  GET_TASKS_APPOINTMENTS,
  GET_REALTOR_APPOINTMENTS_FOLLOWUPS
} from "../../graphql/queries";
import { getCommunityIds, getCardParams } from "../../utils/helpers";

const UpcomingComingTasksContainer = compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_CALENDAR_DATE_RANGE, { name: "getCalendarDateRange" }),
  graphql(SET_CALENDAR_DATE_RANGE, { name: "setCalendarDateRange" }),
  graphql(GET_SHOW_MY_APPOINTMENTS_ONLY, { name: "getShowMyAppointmentsOnly" }),
  graphql(SET_SHOW_MY_APPOINTMENTS_ONLY, { name: "setShowMyAppointmentsOnly" }),
  graphql(GET_SELECTED_CSM, { name: "selectedCsm" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_DEFAULT_VIEW, { name: "getDefaultView" }),
  graphql(SELECT_DEFAULT_VIEW, { name: "selectDefaultView" }),
  graphql(GET_TASKS_APPOINTMENTS, {
    name: "data",
    options: ({ getTableParams: { tableParams }, getDefaultView, ...props }) => {
      const tableView = getDefaultView.defaultView.tableView
      const { start, end } = props.getCalendarDateRange.selectedCalendarDateRange
      const calendarVariables = {
        limit: 1050,
        pageNum: 0,
        order: null,
        search: "",
        filter: {
          calendar_start_dateTime: start,
          calendar_end_dateTime: end,
          past_activities: true
        }
      }
      const params = tableView ? getCardParams("Upcoming Tasks", tableParams) : calendarVariables

      return {
        variables: {
          filter: {
            ...(params?.filter),
            community_ids: getCommunityIds(props.selectedCommunity),
            division_id: props.selectedDivision.selectedDivision.id || null,
            csm_id: props.selectedCsm.selectedCsmIds.ids,
            showMyAppointmentsOnly: props.getShowMyAppointmentsOnly.showMyAppointmentsOnly?.show
          },
          pageNum: params?.pageNum || 0,
          limit: params?.limit || 10,
          order: params?.order && params.order.id !== -1
            ? `${params.order.name} ${params.order.sort}`
            : null,
          search: params?.search || ""
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(GET_COMMUNITIES, { name: "Community" }),
  graphql(GET_ALLRANKINGS, { name: "Rankings" }),
  graphql(GET_CSM, { name: "Csm" }),
  graphql(TASK_CHANGE_CSM, { name: "taskChangeCSM" }),
  graphql(MARK_PROSPECT_APPOINTMENT_DONE, { name: "markDoneProspectAppointment" }),
  graphql(MARK_LEAD_APPOINTMENT_DONE, { name: "markDoneLeadAppointment" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(MARK_PROSPECT_FOLLOW_UP_DONE, { name: "markDoneProspectFollowUp" }),
  graphql(MARK_LEAD_FOLLOW_UP_DONE, { name: "markDoneLeadFollowUp" }),
  graphql(GET_REALTOR_APPOINTMENTS_FOLLOWUPS, {
    name: "getAllRealtorAppointmentsAndFollowups",
    options: ({ getTableParams: { tableParams }, getDefaultView, ...props }) => {
      const tableView = getDefaultView.defaultView.tableView
      const { start, end } = props.getCalendarDateRange.selectedCalendarDateRange
      const calendarVariables = {
        limit: 1050,
        pageNum: 0,
        order: null,
        search: "",
        filter: {
          calendar_start_dateTime: start,
          calendar_end_dateTime: end,
          past_activities: true
        }
      }
      const params = tableView ? getCardParams("Upcoming Tasks", tableParams) : calendarVariables

      return {
        variables: {
          filter: {
            ...(params?.filter),
            community_ids: getCommunityIds(props.selectedCommunity),
            division_id: props.selectedDivision.selectedDivision.id || null,
            csm_id: props.selectedCsm.selectedCsmIds.ids,
            showMyAppointmentsOnly: props.getShowMyAppointmentsOnly.showMyAppointmentsOnly?.show
          },
          pageNum: params?.pageNum || 0,
          limit: params?.limit || 10,
          order: params?.order && params.order.id !== -1
            ? `${params.order.name} ${params.order.sort}`
            : null,
          search: params?.search || ""
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(MARK_REALTOR_APPOINTMENT_DONE, {name: "markDoneRealtorAppointment"}),
  graphql(MARK_REALTOR_FOLLOWUP_DONE, {name: "markDoneRealtorFollowup"})
)(UpcomingTasksTable);

export default UpcomingComingTasksContainer;
