import React, { useState, useEffect, useMemo } from "react";
import { compose, graphql } from "react-apollo";
import { ButtonToolbar } from "react-bootstrap";

import{ GET_CUSTOMER_LOG } from '../../graphql/queries'
import TableView, {
  TextHeader,
  TextCellWithMapper,
  TextCell,
  DateTimeCell,
} from "../../components/new-table";
import CustomButton from "../../components/custom-button/index";
import Drawer from "../../hoc/Drawer";
import { formatActivityDate } from "../../utils/helpers"

/**
 * this component is to render the history table drawer
 * @param  {boolean} show to show hide the drawer
 * @param {string} name name of the customer
 * @param {function}
 * @param {object} data contains all the information related to the customer and the table
 */
const HistoryLifeCycle = ({show, name, toggleDrawer, data}) => {
  const [log, setlog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setLoading(data.loading);
      if (data) {
        setlog(data.getCustomerLogById? data.getCustomerLogById.Customer_Logs: []);
      }
  }, [data]);

  const columns = useMemo(
    () => [
      {
          id: "1",
          header: "Date",
          accessor: "created_at",
          component: TextHeader,
          sortable: false,
          csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
          cell: { component: DateTimeCell },
      },
      {
        id: "2",
        header: "Title",
        accessor: "description",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TextCell
        }
      },
      {
        id: "3",
        header: "Created By",
        accessor: "Csm",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
        field[accessor]
          ? field[accessor].first_name + " " + field[accessor].last_name
          : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name
        }
      }
    ],
    []
  );

  const tableOptions = {
    title: '',
  };
  
  return (
    <Drawer show={show} toggleSideBar={toggleDrawer}>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">{name}</div>
            <div className="form_TitleBig">
              <div className="d-flex">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "4px"
                  }}
                >
                  Customer Lifecycle
                </h2>
              </div>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                onClick={toggleDrawer}
                btnValue="Close"
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <TableView
          columns={columns}
          data={log}
          tableOptions={tableOptions}
          loading={loading}
        />
      </div>
      
    </Drawer>
  )
}

export default compose(
  graphql(GET_CUSTOMER_LOG, {
      options: props => ({variables: {customer_id: props.id}, fetchPolicy: 'network-only'})
  }),
)(HistoryLifeCycle);